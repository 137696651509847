import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const PcCategories = () => import('@/pages/pcCategories.vue');

export default [
  {
    path: 'pc-categories',
    name: 'PCCategories',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы точек сбора',
    },
    component: PcCategories,
  },
  {
    path: 'pc-categories/:id',
    name: 'PC_CategoriesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы точек сбора',
    },
    component: PcCategories,
  },
];
