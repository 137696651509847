import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Notifications = () => import('@/pages/notifications.vue');

export default [
  {
    path: 'notifications',
    name: 'Notifications',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Уведомления',
    },
    component: Notifications,
  },
];
