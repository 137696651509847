import { roleAdmin, roleManager, roleOperator, roleSuperAdmin } from '@/mixins/roles'

const WorldToursRequests = () => import('@/pages/world-tours/requests.vue');

export default [
  {
    path: '/world-tours/requests',
    name: 'WorldToursRequests',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Список заявок',
      ],
    },
    component: WorldToursRequests,
  },
  {
    path: '/world-tours/requests/:id',
    name: 'WorldToursRequestsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Список заявок',
      ],
    },
    component: WorldToursRequests,
  },
];
