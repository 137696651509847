import { roleAdmin, roleManager, roleOperator, roleSuperAdmin } from '@/mixins/roles'

const OrganisationToursTourOperators = () => import('@/pages/organisation-tours/tours.vue');

export default [
  {
    path: '/organisation-tours/tours',
    name: 'OrganisationTours',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Туры',
      ],
    },
    component: OrganisationToursTourOperators,
  },
  {
    path: '/organisation-tours/tours/:id',
    name: 'OrganisationToursForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Туры',
      ],
    },
    component: OrganisationToursTourOperators,
  },
];
