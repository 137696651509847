import { roleAdmin, roleSuperAdmin, roleManager, roleEditor } from '@/mixins/roles';

const Transfers = () => import('@/pages/transfers.vue');

export default [
  {
    path: 'transfers',
    name: 'Transfers',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Трансферы',
    },
    component: Transfers,
  },
  {
    path: 'transfers/:id',
    name: 'TransfersEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Трансферы',
    },
    component: Transfers,
  },
];
