import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Logs = () => import('@/pages/logs.vue');

export default [
  {
    path: 'logs',
    name: 'Logs',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Logs',
    },
    component: Logs,
  },
  {
    path: 'logs/:id',
    name: 'LogsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Logs',
    },
    component: Logs,
  },
];
