const findItem = {
  methods: {
    findItemBy (property, value, itemsList) {
      let result;
      if (itemsList.length) {
        for (let i = 0; i < itemsList.length; i++) {
          if (itemsList[i][property]) {
            if (itemsList[i][property] === value) {
              result = itemsList[i];
              break;
            }
          }
        }
        return result;
      }
    },
  },
};

export default findItem;
