import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsRestTypes = () => import('@/pages/hotels/restTypes.vue');

export default [
  {
    path: '/hotels/rest-types',
    name: 'HotelsRestTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Тип отдыха',
      ],
    },
    component: HotelsRestTypes,
  },
  {
    path: '/hotels/rest-types/:id',
    name: 'HotelsRestTypesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Тип отдыха',
      ],
    },
    component: HotelsRestTypes,
  },
];
