import _filter from 'lodash/filter';
import _map from 'lodash/map';

const itemsTableOperations = {
  data () {
    return {
      checkedAll: false,
      checkedItems: [],
    };
  },

  computed: {
    paginationFactor () {
      return (this.paginationOptions.current_page - 1) * this.paginationOptions.per_page;
    },
  },

  watch: {
    data: {
      handler (val) {
        this.checkedItems = Array(this.data.length).fill(false);
      },
      deep: true,
    },
    checkedItems: {
      handler (val) {
        const checkedIds = _filter(
          _map(this.data, (item, index) => (val[index] === true ? item.id : null)),
          (index) => index !== null
        );
        this.$emit('checkboxChange', checkedIds);
      },
      deep: true,
    },
  },

  methods: {
    selectAll () {
      this.checkedItems.fill(!this.checkedAll);
    },
    selectItem () {
      this.checkedAll = false;
    },
    editItem (item) {
      this.$emit('editItem', item);
    },
    sortBy (type) {
      this.$emit('sortBy', type);
    },
  },
};

export default itemsTableOperations;
