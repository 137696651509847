import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursCities = () => import('@/pages/world-tours/cities.vue');

export default [
  {
    path: '/world-tours/cities',
    name: 'WorldToursCities',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Города',
      ],
    },
    component: WorldToursCities,
  },
  {
    path: '/world-tours/cities/:id',
    name: 'WorldToursCitiesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Города',
      ],
    },
    component: WorldToursCities,
  },
];
