const remoteSelectHelper = {
  data () {
    return {
      loadingAgencies: false,
      loadingAgents: false,
      queryTimer: null,
    };
  },

  methods: {
    resetList (listName) {
      this[listName] = [];
    },

    getByQuery (query, listName, callback) {
      if (query.length) {
        if (this.queryTimer) {
          clearTimeout(this.queryTimer);
        }
        this.queryTimer = setTimeout(() => {
          this.queryTimer = null;
          callback();
        }, 100);
      } else {
        this.resetList(listName);
      }
    },

    agenciesByQuery (query) {
      this.getByQuery(query, 'agenciesList', () => {
        this.loadingAgencies = true;
        const getData = {
          pageOptions: { q: query },
        };
        this.fetchAgencies(getData);
      });
    },

    agentsByQuery (query) {
      this.getByQuery(query, 'agentsList', () => {
        this.loadingAgents = true;
        const getData = {
          pageOptions: { q: query },
        };
        this.fetchAgents(getData);
      });
    },
  },
};

export default remoteSelectHelper;
