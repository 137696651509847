import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursCategories = () => import('@/pages/world-tours/categories.vue');

export default [
  {
    path: '/world-tours/categories',
    name: 'WorldToursCategories',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Категории',
      ],
    },
    component: WorldToursCategories,
  },
  {
    path: '/world-tours/categories/:id',
    name: 'WorldToursCategoriesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Категории',
      ],
    },
    component: WorldToursCategories,
  },
];
