import standardAPI from '@/api/standard_items';

const state = {
  price_lists: [],
  price_lists_status: '',
  price_lists_MetaData: {},
  price_lists_page: 1,
  price_lists_perPage: 10,
};

const responseUrl = '/price-list';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_price_lists ({ commit }, getOptions) {
    commit('PRICE_LISTS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('PRICE_LISTS_SUCCESS', response);
            commit('PRICE_LISTS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('PRICE_LISTS_READY');
            reject(response);
          } else {
            commit('PRICE_LISTS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_price_lists_prices ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl + '/prices',
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response.data && response.data.length) {
            // commit('PRICE_LISTS_SUCCESS', response);
            // commit('PRICE_LISTS_READY');
            resolve(response);
          } else {
            // commit('PRICE_LISTS_ERROR')
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_allowed_dates ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl + '/allowed-dates',
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response.dates && response.daysWeek) {
            // commit('PRICE_LISTS_SUCCESS', response);
            // commit('PRICE_LISTS_READY');
            resolve(response);
          } else {
            // commit('PRICE_LISTS_ERROR')
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_price_list ({ commit, dispatch }, itemData) {
    commit('PRICE_LISTS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          commit('PRICE_LISTS_READY');
          // commit('PRICE_LISTS_REQUEST')
          resolve(response);
        } else {
          commit('PRICE_LISTS_READY');
          reject(response);
        }
      });
    });
  },

  edit_price_list ({ commit, dispatch }, itemData) {
    commit('PRICE_LISTS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          commit('PRICE_LISTS_READY');
          resolve(response);
        } else {
          commit('PRICE_LISTS_READY');
          reject(response);
        }
      });
    });
  },
  remove_price_list ({ commit, dispatch }, itemData) {
    commit('PRICE_LISTS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          commit('PRICE_LISTS_READY');
          resolve();
        } else {
          commit('PRICE_LISTS_READY');
          reject(response);
        }
      });
    });
  },
  reorder_price_list ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.price_lists_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.price_lists_perPage = max;
  },
  PRICE_LISTS_READY: (state) => {
    state.price_lists_status = 'ready';
  },
  PRICE_LISTS_REQUEST: (state) => {
    state.price_lists_status = 'loading';
  },
  PRICE_LISTS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.price_lists_MetaData.meta = response.meta;
      state.price_lists_MetaData.links = response.links;
    }
    state.price_lists = response.data;
    state.price_lists_status = 'success';
  },
  PRICE_LISTS_ERROR: (state) => {
    state.price_lists_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
