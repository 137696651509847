<template>
  <div class="wrapper">
    <div class="wrapper__content">
      <h1>
        Page Not Found
      </h1>
      <small>
        Oops! It seems that this page does not exist.
        <br>
        You can navigate back
        <router-link to="/">to main page</router-link>
      </small>
    </div>
    <div
      class="wrapper__background"
      style="background-image: url(/static/img/background/background_login.jpg)"
    />
  </div>
</template>

<script>
export default {};
</script>

<style
  lang="scss"
  scoped
>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
  min-height: 100vh;
  position: relative;

  &__content {
    border-radius: 6px;
    padding: 1rem;
    width: 100%;
    max-width: 340px;
    background-color: #fff;
    box-shadow: 0 6px 10px -4px rgba(#000, 0.15);
    position: relative;
    z-index: 3;
  }

  &::after,
  &__background {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(#000, 0.7);
  }

  &::after {
    content: '';
    z-index: 2;
  }

  &__background {
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  h1 {
    margin: 0 0 1rem;
    font-weight: normal;
    text-align: center;
    font-size: 28px;
  }

  .el-button {
    width: 100%;
  }
}
</style>
