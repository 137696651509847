import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const TransitPoints = () => import('@/pages/transitPoints');

export default [
  {
    path: 'transit-points',
    name: 'TransitPoints',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Точки стыковки',
    },
    component: TransitPoints,
  },
  {
    path: 'transit-points/:id',
    name: 'TransitPointsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Точки стыковки',
    },
    component: TransitPoints,
  },
];
