const roleAdmin = 1;
const roleManager = 2;
const roleAgent = 3;
const roleUser = 4;
const roleSuperAdmin = 5;
const roleOperator = 7;
const roleEditor = 8;
const roleHoliday = 9;

const roles = {
  data () {
    return {
      roles: {
        [roleAdmin]: 'admin',
        [roleManager]: 'manager',
        [roleAgent]: 'agent',
        [roleUser]: 'user',
        [roleSuperAdmin]: 'superAdmin',
        [roleOperator]: 'operator',
        [roleEditor]: 'editor',
        [roleHoliday]: 'holiday',
      },
    };
  },
  methods: {
    isDenyAccessForRole (roleStr) {
      if (this.$auth.user()) {
        const role = this.roles[this.$auth.user().role];
        return role !== roleStr;
      }
      return true;
    },
  },
};

const rolesRu = {
  data () {
    return {
      rolesRu: {
        [roleAdmin]: {
          id: roleAdmin,
          name: 'Администратор экскурсий',
        },
        [roleManager]: {
          id: roleManager,
          name: 'Менеджер экскурсий',
        },
        [roleAgent]: {
          id: roleAgent,
          name: 'Агент',
        },
        [roleUser]: {
          id: roleUser,
          name: 'Обычный пользователь',
        },
        [roleSuperAdmin]: {
          id: roleSuperAdmin,
          name: 'Супер Администратор',
        },
        [roleOperator]: {
          id: roleOperator,
          name: 'Оператор эксурсий',
        },
        [roleEditor]: {
          id: roleEditor,
          name: 'Редактор',
        },
        // [roleHoliday]: {
        //   id: roleHoliday,
        //   name: 'Holiday',
        // },
      },
    };
  },
};

export { roles as default, roles, rolesRu, roleAdmin, roleManager, roleAgent, roleUser, roleSuperAdmin, roleOperator, roleEditor, roleHoliday };
