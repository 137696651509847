export default {
  baseURL: process.env.VUE_APP_API,

  toUrl (obj) {
    const str = [];
    for (const p in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(p)) {
        if (obj[p] || obj[p] === false) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return str.join('&');
  },

  standardResponse (options, callback) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (options.token) {
      headers.Authorization = 'Bearer ' + options.token;
    }

    fetch(options.url, {
      method: options.method,
      headers: headers,
      body: options.body ? JSON.stringify(options.body) : null,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {
        callback(responseData);
      });
  },

  uploadResponse (options, callback) {
    const headers = {};
    const formData = new FormData();
    if (options.body) {
      for (const name in options.body) {
        formData.append(name, options.body[name]);
      }
    }

    if (options.token) {
      headers.Authorization = 'Bearer ' + options.token;
    }

    fetch(options.url, {
      method: options.method,
      headers: headers,
      body: formData,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {
        callback(responseData);
      });
  },
};
