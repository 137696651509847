import standardAPI from '@/api/standard_items';

const state = {
  pages: [],
  pages_status: '',
  pages_MetaData: {},
  pages_page: 1,
  pages_perPage: 10,
};

const responseUrl = '/static/pages';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_pages ({ commit }, getOptions) {
    commit('PAGES_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        if (response && response.data) {
          commit('PAGES_SUCCESS', response);
          commit('PAGES_READY');
          resolve(response);
        } else {
          commit('PAGES_ERROR');
          reject(response);
        }
      }, preparedOptions);
    });
  },
  add_page ({ commit, dispatch }, itemData) {
    commit('PAGES_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('PAGES_READY');
          reject(response);
        }
      });
    });
  },
  edit_page ({ commit, dispatch }, itemData) {
    commit('PAGES_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('PAGES_READY');
          reject(response);
        }
      });
    });
  },
  remove_page ({ commit, dispatch }, itemData) {
    commit('PAGES_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('PAGES_READY');
          reject(response);
        }
      });
    });
  },
  reorder_page ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  upload_image ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      url: '/upload-file/temp',
    };
    return new Promise((resolve, reject) => {
      standardAPI.upload_file(preparedData, (response) => {
        if (response.id) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.pages_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.pages_perPage = max;
  },
  PAGES_READY: (state) => {
    state.pages_status = 'ready';
  },
  PAGES_REQUEST: (state) => {
    state.pages_status = 'loading';
  },
  PAGES_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.pages_MetaData.meta = response.meta;
      state.pages_MetaData.links = response.links;
    }
    state.pages = response.data;
    state.pages_status = 'success';
  },
  PAGES_ERROR: (state) => {
    state.pages_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
