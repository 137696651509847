import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Clients = () => import('@/pages/clients.vue');

export default [
  {
    path: 'clients',
    name: 'Clients',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Клиенты',
    },
    component: Clients,
  },
  {
    path: 'clients/:id',
    name: 'ClientsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Клиенты',
    },
    component: Clients,
  },
];
