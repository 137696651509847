const windowMethods = {
  created () {
    this.updateTitle();
  },

  watch: {
    showModal () {
      this.updateTitle();
    },

    item_id (val, oldVal) {
      this.updateUrl(val, oldVal);
    },
  },

  methods: {
    updateTitle () {
      const title = ['Atlantis Travel'];
      if (Array.isArray(this.$route.meta.title)) {
        this.$route.meta.title.forEach((item) => {
          title.push(item.replace(/<\/?[^>]+>/gi, ''));
        });
      } else if (typeof this.$route.meta.title === 'string') {
        title.push(this.$route.meta.title.replace(/<\/?[^>]+>/gi, ''));
      }
      if (this.showModal) {
        title.push(this.modalSettings.title.replace(/<\/?[^>]+>/gi, ''));
      }
      window.document.title = title.reverse().join(' :: ');
    },

    updateUrl (id = null, oldId = null) {
      const url = window.location.toString().split('/');
      if (!id && oldId && url[url.length - 1].toString() === oldId.toString()) {
        url.pop();
      } else if (id && url[url.length - 1].toString() !== id.toString()) {
        url.push(id);
      }
      window.history.pushState('', '', url.join('/'));
    },
  },
};

export default windowMethods;
