import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Settings = () => import('@/pages/settings.vue');

export default [
  {
    path: 'settings',
    name: 'Settings',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Настройки',
    },
    component: Settings,
  },
];
