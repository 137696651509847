export default {
  beforeRouteLeave (to, from, next) {
    if (this.formDataChanged) {
      // Prevent change route if form changed
      this.$refs.modal.tryCloseModal(to);
      next(false);
    } else {
      next();
    }
  },
};
