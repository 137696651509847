import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsOrders = () => import('@/pages/hotels/orders.vue');

export default [
  {
    path: '/hotels/orders',
    name: 'HotelsOrders',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Заказы',
      ],
    },
    component: HotelsOrders,
  },
  {
    path: '/hotels/orders/:id',
    name: 'HotelsOrdersForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Заказы',
      ],
    },
    component: HotelsOrders,
  },
];
