import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Contacts = () => import('@/pages/contacts.vue');

export default [
  {
    path: 'contacts',
    name: 'Contacts',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Контакты',
    },
    component: Contacts,
  },
  {
    path: 'contacts/:id',
    name: 'ContactFormEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Контакты',
    },
    component: Contacts,
  },
];
