import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const OrganisationToursTourTypes = () => import('@/pages/organisation-tours/tourTypes.vue');

export default [
  {
    path: '/organisation-tours/tourTypes',
    name: 'OrganisationToursTourTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Типы туров',
      ],
    },
    component: OrganisationToursTourTypes,
  },
  {
    path: '/organisation-tours/tourTypes',
    name: 'OrganisationToursTourTypesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Типы туров',
      ],
    },
    component: OrganisationToursTourTypes,
  },
];
