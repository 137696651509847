import { roleAdmin, roleSuperAdmin, roleManager, roleEditor } from '@/mixins/roles';

const Attractions = () => import('@/pages/attractions.vue');

export default [
  {
    path: 'attractions',
    name: 'Attractions',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Достопримечательности',
    },
    component: Attractions,
  },
  {
    path: 'attractions/:id',
    name: 'AttractionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Достопримечательности',
    },
    component: Attractions,
  },
];
