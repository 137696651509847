import standardAPI from '@/api/standard_items';

const state = {
  orders_status: '',
  orders_MetaData: {},
  orders_page: 1,
  orders_perPage: 10,
};

const responseUrl = '/orders';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },

  fetch_orders ({ commit }, getOptions) {
    commit('ORDERS_REQUEST');
    const preparedData = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('ORDERS_SUCCESS', response);
            commit('ORDERS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('ORDERS_READY');
            reject(response);
          } else {
            commit('ORDERS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedData);
    });
  },

  fetch_order_silent ({ commit }, getOptions) {
    const preparedData = {
      getParams: getOptions.pageOptions,
      url: getOptions.url || responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedData);
    });
  },

  add_order ({ commit, dispatch }, itemData) {
    commit('ORDERS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };

    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          commit('ORDERS_READY');
          resolve(response);
        } else {
          commit('ORDERS_READY');
          reject(response);
        }
      });
    });
  },

  edit_order ({ commit, dispatch }, itemData) {
    commit('ORDERS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          commit('ORDERS_READY');
          resolve(response);
        } else {
          commit('ORDERS_READY');
          reject(response);
        }
      });
    });
  },

  reorder_order ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  order_operations ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      method: itemData.method || 'put',
      url: itemData.url,
    };
    return new Promise((resolve, reject) => {
      standardAPI.universal_response(preparedData, (response) => {
        if (response.status && response.status !== 403) {
          if (response.status === 'appointed') {
            resolve(response);
          }
          if (response.status === 'removed') {
            resolve(response);
          }
          if (response.status === 'taken') {
            resolve(response);
          }
          if (response.status === 'changed') {
            resolve(response);
          }
          if (response.status === 'sent') {
            resolve(response);
          }
          if (response.status === 'success') {
            resolve(response);
          }
          if (response.status === 'created') {
            resolve(response);
          }
          if (response.status === 'paid') {
            resolve(response);
          }
        } else if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.orders_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.orders_perPage = max;
  },
  ORDERS_READY: (state) => {
    state.orders_status = 'ready';
  },
  ORDERS_REQUEST: (state) => {
    state.orders_status = 'loading';
  },
  ORDERS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.orders_MetaData.meta = response.meta;
      state.orders_MetaData.links = response.links;
    }
    state.orders_status = 'success';
  },
  ORDERS_ERROR: (state) => {
    state.orders_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
