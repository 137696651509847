import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Feedback = () => import('@/pages/feedback.vue');

export default [
  {
    path: 'feedback',
    name: 'Feedback',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Отзывы',
    },
    component: Feedback,
  },
  {
    path: 'feedback/:id',
    name: 'FeedbackEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Отзывы',
    },
    component: Feedback,
  },
];
