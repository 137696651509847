import standardAPI from '@/api/standard_items';

const responseUrl = '/world-tours/movement-types';

const state = {
  movement_types: [],
  movement_types_MetaData: {},
  movement_types_status: '',
  movement_types_page: 1,
  movement_types_perPage: 10,
};

const getters = {
  get_movement_types: (state) => {
    return state.movement_types;
  },
  get_movement_types_status: (state) => {
    return state.movement_types_status;
  },
};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_movement_types ({ commit }, getOptions) {
    commit('DATA_MOVEMENT_TYPES_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('DATA_MOVEMENT_TYPES_UPDATE_SUCCESS', response);
            commit('DATA_MOVEMENT_TYPES_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('DATA_MOVEMENT_TYPES_READY');
            reject(response);
          } else {
            commit('DATA_MOVEMENT_TYPES_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_movement_types_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_movement_type ({ commit, dispatch }, itemData) {
    commit('DATA_MOVEMENT_TYPES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          commit('DATA_MOVEMENT_TYPES_REQUEST');
          resolve(response);
        } else {
          commit('DATA_MOVEMENT_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  edit_movement_type ({ commit, dispatch }, itemData) {
    commit('DATA_MOVEMENT_TYPES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          commit('DATA_MOVEMENT_TYPES_REQUEST');
          resolve(response);
        } else {
          commit('DATA_MOVEMENT_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  remove_movement_types ({ commit, dispatch }, itemData) {
    commit('DATA_MOVEMENT_TYPES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          commit('DATA_MOVEMENT_TYPES_REQUEST');
          resolve();
        } else {
          commit('DATA_MOVEMENT_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  reorder_movement_types ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.movement_types_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.movement_types_perPage = max;
  },
  DATA_MOVEMENT_TYPES_READY: (state) => {
    state.movement_types_status = 'ready';
  },
  DATA_MOVEMENT_TYPES_REQUEST: (state) => {
    state.movement_types_status = 'loading';
  },
  DATA_MOVEMENT_TYPES_UPDATE_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.movement_types_MetaData.meta = response.meta;
      state.movement_types_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.movement_types = response.data || [];
    }
    state.movement_types_status = 'success';
  },
  DATA_MOVEMENT_TYPES_ERROR: (state) => {
    state.movement_types_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
