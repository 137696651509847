import { roleAdmin, roleEditor, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursExcursions = () => import('@/pages/world-tours/excursions.vue');

export default [
  {
    path: '/world-tours/excursions',
    name: 'WorldToursExcursions',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Экскурсии',
      ],
    },
    component: WorldToursExcursions,
  },
  {
    path: '/world-tours/excursions/:id',
    name: 'WorldToursExcursionsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Экскурсии',
      ],
    },
    component: WorldToursExcursions,
  },
];
