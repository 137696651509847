import { roleAdmin, roleSuperAdmin, roleManager, roleEditor } from '@/mixins/roles'

const Cities = () => import('@/pages/cities.vue');

export default [
  {
    path: 'cities',
    name: 'Cities',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Города',
    },
    component: Cities,
  },
  {
    path: 'cities/:id',
    name: 'CitiesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Города',
    },
    component: Cities,
  },
];
