import { roleAdmin, roleManager, roleSuperAdmin, roleOperator } from '@/mixins/roles';

const OrganisationToursCountries = () => import('@/pages/organisation-tours/countries.vue');

export default [
  {
    path: '/organisation-tours/countries',
    name: 'OrganisationToursCountries',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Страны',
      ],
    },
    component: OrganisationToursCountries,
  },
  {
    path: '/organisation-tours/countries/:id',
    name: 'OrganisationToursCountriesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Страны',
      ],
    },
    component: OrganisationToursCountries,
  },
];
