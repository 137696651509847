import standardAPI from '@/api/standard_items';

const responseUrl = '/organisation-tours/orders';

const state = {
  orders: [],
  orders_MetaData: {},
  orders_status: '',
  orders_page: 1,
  orders_perPage: 10,
};

const getters = {
  get_orders: (state) => {
    return state.orders;
  },
  get_orders_status: (state) => {
    return state.orders_status;
  },
};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_orders ({ commit }, getOptions) {
    commit('DATA_ORDERS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('DATA_ORDERS_UPDATE_SUCCESS', response);
            commit('DATA_ORDERS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('DATA_ORDERS_READY');
            reject(response);
          } else {
            commit('DATA_ORDERS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_orders_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  change_request_status ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData,
      url: responseUrl + '/' + itemData.id + '/change-status',
      method: 'put',
    };
    return new Promise((resolve, reject) => {
      standardAPI.universal_response(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.orders_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.orders_perPage = max;
  },
  DATA_ORDERS_READY: (state) => {
    state.orders_status = 'ready';
  },
  DATA_ORDERS_REQUEST: (state) => {
    state.orders_status = 'loading';
  },
  DATA_ORDERS_UPDATE_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.orders_MetaData.meta = response.meta;
      state.orders_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.orders = response.data || [];
    }
    state.orders_status = 'success';
  },
  DATA_ORDERS_ERROR: (state) => {
    state.orders_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
