import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const BlogAuthors = () => import('@/pages/blog/authors.vue');

export default [
  {
    path: 'blog/authors',
    name: 'BlogAuthors',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Авторы',
      ],
    },
    component: BlogAuthors,
  },
  {
    path: 'blog/authors/:id',
    name: 'BlogAuthorsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Авторы',
      ],
    },
    component: BlogAuthors,
  },
];
