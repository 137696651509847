import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Redirects = () => import('@/pages/redirects.vue');

export default [
  {
    path: 'redirects',
    name: 'Redirects',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Redirects',
    },
    component: Redirects,
  },
  {
    path: 'redirects/:id',
    name: 'RedirectsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Redirects',
    },
    component: Redirects,
  },
];
