import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Seo = () => import('@/pages/seo.vue');

export default [
  {
    path: 'seo',
    name: 'Seo',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'SEO',
    },
    component: Seo,
  },
  {
    path: 'seo/:id',
    name: 'SeoEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'SEO',
    },
    component: Seo,
  },
];
