import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursGuides = () => import('@/pages/world-tours/guides.vue');

export default [
  {
    path: '/world-tours/guides',
    name: 'WorldToursGuides',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Гиды',
      ],
    },
    component: WorldToursGuides,
  },
  {
    path: '/world-tours/guides/:id',
    name: 'WorldToursGuidesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Гиды',
      ],
    },
    component: WorldToursGuides,
  },
];
