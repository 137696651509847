import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Pages = () => import('@/pages/Pages/index.vue');

export default [
  {
    path: 'pages',
    name: 'Pages',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Страницы',
    },
    component: Pages,
  },
  {
    path: 'pages/:id',
    name: 'PagesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Страницы',
    },
    component: Pages,
  },
];
