import { roleAdmin, roleSuperAdmin, roleManager, roleOperator } from '@/mixins/roles';

const Orders = () => import('@/pages/orders.vue');

export default [
  {
    path: 'orders',
    name: 'Orders',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Заказы',
    },
    component: Orders,
    props: true,
  },
  {
    path: 'orders/:id',
    name: 'OrdersEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Заказы',
    },
    component: Orders,
  },
];
