import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const ExcursionCategoryTypes = () => import('@/pages/excursionCategoryTypes.vue');

export default [
  {
    path: 'excursion-categories-types',
    name: 'CategoryTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Классы экскурсий',
    },
    component: ExcursionCategoryTypes,
  },
  {
    path: 'excursion-categories-types/:id',
    name: 'CategoryTypesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Классы экскурсий',
    },
    component: ExcursionCategoryTypes,
  },
];
