import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const OrganisationToursOrders = () => import('@/pages/organisation-tours/orders.vue');

export default [
  {
    path: '/organisation-tours/orders',
    name: 'OrganisationToursOrders',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Заказы',
      ],
    },
    component: OrganisationToursOrders,
  },
  {
    path: '/organisation-tours/orders/:id',
    name: 'OrganisationToursOrdersForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Заказы',
      ],
    },
    component: OrganisationToursOrders,
  },
];
