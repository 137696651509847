import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const CollectionPoints = () => import('@/pages/collectionPoints.vue');

export default [
  {
    path: 'collection-points',
    name: 'CollectionPoints',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Точки сбора',
    },
    component: CollectionPoints,
  },
  {
    path: 'collection-points/:id',
    name: 'CollectionPointsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Точки сбора',
    },
    component: CollectionPoints,
  },
];
