import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';
const BlogArticles = () => import('@/pages/blog/articles.vue');

export default [
  {
    path: 'blog/articles',
    name: 'BlogArticles',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Статьи',
      ],
    },
    component: BlogArticles,
  },
  {
    path: 'blog/articles/:id',
    name: 'BlogArticlesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Статьи',
      ],
    },
    component: BlogArticles,
  },
];
