import { roleAdmin, roleSuperAdmin, roleManager, roleOperator, roleHoliday } from '@/mixins/roles';

const Agencies = () => import('@/pages/agencies.vue');

export default [
  {
    path: 'agencies',
    name: 'Agencies',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator, roleHoliday],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Агентства',
    },
    component: Agencies,
  },
  {
    path: 'agencies/:id',
    name: 'AgenciesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Агентства',
    },
    component: Agencies,
  },
];
