<template>
  <VField
    v-slot="{ field, errorMessage }"
    :name="name"
    :rules="rules"
    :value="value"
    :label="label"
  >
    <el-form-item
      :label="labelPosition === 'hidden' ? '' : label"
      :label-width="labelPosition === 'hidden' ? 0 :labelWidth"
      :error="errorMessage"
      :class="`el-form-item--${labelPosition}`"
      :required="required"
    >
      <template
        v-if="labelPosition !== 'hidden'"
        #label
      >
        {{ label }}
        <el-tooltip
          v-if="tooltip.length"
          :content="tooltip"
          placement="top-end"
        >
          <el-icon>
            <icon-question />
          </el-icon>
        </el-tooltip>
      </template>
      <el-input
        v-model="value"
        v-loading="loading"
        v-bind="(({ value, ...o }) => o)({ ...field, ...$attrs })"
        :placeholder="placeholder || ''"
        :validate-event="false"
      >
        <template
          v-if="prepend.length"
          #prepend
        >
          {{ prepend }}
        </template>
        <template
          v-if="append.length"
          #append
        >
          {{ append }}
        </template>
      </el-input>
      <slot name="append" />
    </el-form-item>
  </VField>
</template>

<script>
import { Field as VField } from 'vee-validate';
import { QuestionFilled as IconQuestion } from '@element-plus/icons-vue';

export default {
  name: 'InputWithValidation',
  components: {
    IconQuestion,
    VField,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelPosition: {
      type: String,
      default: 'right',
    },
    labelWidth: {
      type: Number,
      default: parseInt(process.env.VUE_APP_LABEL_WIDTH, 10),
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue;
      },
      set (value) {
        this.$emit('update:modelValue', value);
      },
    },
    required () {
      return this.rules.split('|').indexOf('required') !== -1;
    },
  },
};
</script>

<style lang="scss">
.el-textarea__inner {
  min-height: calc(var(--el-component-size-large) * 4) !important;
}
</style>
