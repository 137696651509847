import { roleAdmin, roleSuperAdmin, roleManager, roleOperator } from '@/mixins/roles';

const Packages = () => import('@/pages/packages.vue');

export default [
  {
    path: 'packages',
    name: 'Packages',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Пакеты',
    },
    component: Packages,
  },
  {
    path: 'packages/:id',
    name: 'PackagesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Пакеты',
    },
    component: Packages,
  },
];
