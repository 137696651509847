import standardAPI from '@/api/standard_items';

const state = {
  regions: [],
  regions_status: '',
  regions_MetaData: {},
  regions_page: 1,
  regions_perPage: 10,
};

const responseUrl = '/regions';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_regions ({ commit }, getOptions) {
    commit('REGIONS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('REGIONS_SUCCESS', response);
            commit('REGIONS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('REGIONS_READY');
            reject(response);
          } else {
            commit('REGIONS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_region ({ commit, dispatch }, itemData) {
    commit('REGIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('REGIONS_READY');
          reject(response);
        }
      });
    });
  },
  edit_region ({ commit, dispatch }, itemData) {
    commit('REGIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('REGIONS_READY');
          reject(response);
        }
      });
    });
  },
  remove_region ({ commit, dispatch }, itemData) {
    commit('REGIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('REGIONS_READY');
          reject(response);
        }
      });
    });
  },
  reorder_region ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.regions_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.regions_perPage = max;
  },
  REGIONS_READY: (state) => {
    state.regions_status = 'ready';
  },
  REGIONS_REQUEST: (state) => {
    state.regions_status = 'loading';
  },
  REGIONS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.regions_MetaData.meta = response.meta;
      state.regions_MetaData.links = response.links;
    }
    state.regions = response.data ? response.data.map((region) => {
      region.checked = false; // need for some pages where we can select region
      region.cities = region.cities.map((city) => {
        city.checked = false; // need for some pages where we can select cities of region
        return city;
      });
      return region;
    }) : [];
    state.regions_status = 'success';
  },
  REGIONS_ERROR: (state) => {
    state.regions_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
