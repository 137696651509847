import api from './api';

export default {
  get_items (callback, getData) {
    const getParams = api.toUrl(getData.getParams);
    const options = {
      token: getData.token,
      url: api.baseURL + getData.url + '?' + getParams,
      method: 'get',
    };
    if (getData.getParams && getData.getParams.id) {
      options.url = api.baseURL + getData.url + '/' + getData.getParams.id;
    }
    api.standardResponse(options, callback);
  },

  add_item (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url,
      method: 'post',
      body: data.itemData.formData,
    };
    api.standardResponse(options, callback);
  },

  edit_item (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url + data.itemData.formData.id,
      method: 'put',
      body: data.itemData.formData,
    };
    api.standardResponse(options, callback);
  },

  remove_items (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url,
      method: 'delete',
      body: data.itemData.checkedIds || data.itemData.id,
    };
    if (!data.itemData.checkedIds) {
      options.url = options.url + '/' + data.itemData.id;
    }
    api.standardResponse(options, callback);
  },

  reorder_item (data, callback) {
    const options = {
      token: data.orderData.token,
      url: api.baseURL + data.url,
      method: 'post',
      body: data.orderData.orderData,
    };
    api.standardResponse(options, callback);
  },

  reorder_item_number (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url,
      method: 'put',
      body: data.itemData.payload,
    };
    api.standardResponse(options, callback);
  },

  upload_file (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url,
      method: 'post',
      body: data.itemData.payload,
    };
    api.uploadResponse(options, callback);
  },

  universal_response (data, callback) {
    const options = {
      token: data.itemData.token,
      url: api.baseURL + data.url,
      method: data.method,
    };
    if (data.itemData.payload) {
      options.body = data.itemData.payload;
    }
    api.standardResponse(options, callback);
  },
};
