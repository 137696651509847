import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Team = () => import('@/pages/Team/index.vue');

export default [
  {
    path: 'team',
    name: 'Team',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Команда',
    },
    component: Team,
  },
  {
    path: 'team/:id',
    name: 'TeamEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Команда',
    },
    component: Team,
  },
];
