import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Levels = () => import('@/pages/levels.vue');

export default [
  {
    path: 'levels',
    name: 'Levels',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Уровни',
    },
    component: Levels,
  },
  {
    path: 'levels/:id',
    name: 'LevelsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Уровни',
    },
    component: Levels,
  },
];
