import _get from 'lodash/get';
import _filter from 'lodash/filter';
import moment from 'moment';

const datesHelper = {
  data () {
    return {
      today: null,
      todayDateYear: null,
      todayDateMonth: null,
      todayDayNumber: null,
      nowHours: null,
      parseDateTypes: {
        dateAndTime: 'date-and-time', // YYYY/MM/DD HH:mm:ss
        fullDate: 'full-date', // YYYY/MM/DD
      },
    };
  },

  created () {
    this.today = new Date();
    this.todayDateYear = this.today.getFullYear();
    this.todayDateMonth = this.today.getMonth();
    this.todayDateDay = this.today.getDate();
    this.todayDayNumber = this.today.getDay();
    this.nowHours = this.today.getHours();
  },

  methods: {
    isTomorrow (date) {
      const nowInMs = Date.parse(this.today);
      const tomorrow = new Date(nowInMs + 86400000);
      const tomorrowYear = tomorrow.getFullYear();
      const tomorrowMonth = tomorrow.getMonth();
      const tomorrowDay = tomorrow.getDate();

      const controlDate = new Date(Date.parse(date));
      const controlDateYear = controlDate.getFullYear();
      const controlDateMonth = controlDate.getMonth();
      const controlDateDay = controlDate.getDate();

      return controlDateYear === tomorrowYear && controlDateMonth === tomorrowMonth && controlDateDay === tomorrowDay;
    },

    isToday (controlDate) {
      const controlDateYear = controlDate.getFullYear();
      const controlDateMonth = controlDate.getMonth();
      const controlDateDay = controlDate.getDate();

      return (
        controlDateYear === this.todayDateYear &&
        controlDateMonth === this.todayDateMonth &&
        controlDateDay === this.todayDateDay
      );
    },

    isBeforeToday (controlDate) {
      const startOfToday = this.today.setHours(0, 0, 0, 0);
      const yesterday = startOfToday - 100;
      const controlDateInMs = Date.parse(controlDate);

      return controlDateInMs < yesterday;
    },

    isEqual (pickerDate, controlDate) {
      const pickerDateYear = pickerDate.getFullYear();
      const pickerDateMonth = pickerDate.getMonth();
      const pickerDateDay = pickerDate.getDate();

      const controlDateArr = controlDate.split('-');
      const controlDateYear = +controlDateArr[0];
      const controlDateMonth = +controlDateArr[1] - 1;
      const controlDateDay = +controlDateArr[2];

      return (
        pickerDateYear === controlDateYear && pickerDateMonth === controlDateMonth && pickerDateDay === controlDateDay
      );
    },

    isPeriodic (allowedDate) {
      return !!+_get(this.getPeriod(allowedDate), 'is_periodic');
    },

    periodType (allowedDate) {
      return +_get(this.getPeriod(allowedDate), 'period_type');
    },

    periodFrequencyNumber (allowedDate) {
      return +_get(this.getPeriod(allowedDate), 'period_frequency_number');
    },

    periodDateStart (allowedDate) {
      return _get(this.getPeriod(allowedDate), 'period_date_start');
    },

    hasPeriodByDateRange (allowedDate, dateEnd) {
      const periodDateStart = moment(this.periodDateStart(allowedDate), 'YYYY-MM-DD');
      const duration = moment.duration(dateEnd.diff(periodDateStart));
      switch (this.periodType(allowedDate)) {
        case 1: {
          return (
            periodDateStart.day() % dateEnd.day() === 0 &&
            Math.floor(duration.asDays()) % (7 * this.periodFrequencyNumber(allowedDate)) === 0
          );
        }

        case 2: {
          return (
            periodDateStart.date() % dateEnd.date() === 0 &&
            Math.round(duration.asMonths()) % this.periodFrequencyNumber(allowedDate) === 0
          );
        }

        case 3: {
          return periodDateStart.month() === dateEnd.month() && periodDateStart.date() === dateEnd.date();
        }

        default: {
          return false;
        }
      }
    },

    getPeriod (allowedDate) {
      return _get(allowedDate, 'period');
    },

    getDaysWeek (allowedDate) {
      return this.pluck(_filter(_get(allowedDate, 'daysWeek', [])), 'day_week');
    },

    pluck (array, key) {
      return array.map((o) => o[key]);
    },

    parseDateFormat (date, type) {
      let result = null;
      switch (type) {
        case this.parseDateTypes.dateAndTime:
          result = moment(date, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
          break;
        case this.parseDateTypes.fullDate:
          result = moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
          break;
        default:
          result = moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
          break;
      }
      return result;
    },
  },
};

export default datesHelper;
