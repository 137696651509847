import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsStars = () => import('@/pages/hotels/stars.vue');

export default [
  {
    path: '/hotels/stars',
    name: 'HotelsStars',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Звездность',
      ],
    },
    component: HotelsStars,
  },
  {
    path: '/hotels/stars/:id',
    name: 'HotelsStarsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Звездность',
      ],
    },
    component: HotelsStars,
  },
];
