import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const PagesSections = () => import('@/pages/Pages/sections.vue');

export default [
  {
    path: 'pages/sections',
    name: 'Sections',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Страницы',
        'Разделы',
      ],
    },
    component: PagesSections,
  },
  {
    path: 'pages/sections/:id',
    name: 'SectionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Страницы',
        'Разделы',
      ],
    },
    component: PagesSections,
  },
];
