import standardAPI from '@/api/standard_items';

const state = {
  config_rules: null,
  config_rules_status: '',
};

const responseUrl = '/config/rules';

const getters = {};

const actions = {
  fetch_config_rules ({ commit }, getOptions) {
    commit('CONFIG_RULES_REQUEST');

    const preparedOptions = {
      getParams: null,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items(
        (response) => {
          if (response) {
            commit('CONFIG_RULES_SUCCESS', response);
            commit('CONFIG_RULES_READY');
            resolve(response);
          } else {
            commit('CONFIG_RULES_ERROR');
            reject(response);
          }
        }, preparedOptions);
    });
  },
};

const mutations = {
  CONFIG_RULES_READY: (state) => {
    state.config_rules_status = 'ready';
  },
  CONFIG_RULES_REQUEST: (state) => {
    state.config_rules_status = 'loading';
  },
  CONFIG_RULES_SUCCESS: (state, response) => {
    state.config_rules = response;
    state.config_rules_status = 'success';
  },
  CONFIG_RULES_ERROR: (state) => {
    state.config_rules_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
