import standardAPI from '@/api/standard_items';

const state = {
  hotels: [],
  hotels_status: '',
  hotels_MetaData: {},
  hotels_page: 1,
  hotels_perPage: 10,
};

const responseUrl = '/hotels/hotels';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_hotels ({ commit }, getOptions) {
    commit('HOTELS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('HOTELS_SUCCESS', response);
            commit('HOTELS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('HOTELS_READY');
            reject(response);
          } else {
            commit('HOTELS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_hotels_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_hotel ({ commit, dispatch }, itemData) {
    commit('HOTELS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('HOTELS_READY');
          reject(response);
        }
      });
    });
  },
  edit_hotel ({ commit, dispatch }, itemData) {
    commit('HOTELS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('HOTELS_READY');
          reject(response);
        }
      });
    });
  },
  remove_hotel ({ commit, dispatch }, itemData) {
    commit('HOTELS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('HOTELS_READY');
          reject(response);
        }
      });
    });
  },
  reorder_hotel ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  set_hotel_order ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData,
      url: responseUrl + '/set-reorder-index/' + itemData.id,
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item_number(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.hotels_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.hotels_perPage = max;
  },
  HOTELS_READY: (state) => {
    state.hotels_status = 'ready';
  },
  HOTELS_REQUEST: (state) => {
    state.hotels_status = 'loading';
  },
  HOTELS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.hotels_MetaData.meta = response.meta;
      state.hotels_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.hotels = response.data.map((hotel) => {
        hotel.checked = false; // need for some pages where we can select hotels
        return hotel;
      }) || [];
    }
    state.hotels_status = 'success';
  },
  HOTELS_ERROR: (state) => {
    state.hotels_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
