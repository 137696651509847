import { roleAdmin, roleSuperAdmin, roleEditor, roleManager } from '@/mixins/roles';

const Excursions = () => import('@/pages/excursions.vue');

export default [
  {
    path: 'excursions',
    name: 'Excursions',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Экскурсии',
    },
    component: Excursions,
  },
  {
    path: 'excursions/:id',
    name: 'ExcursionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Экскурсии',
    },
    component: Excursions,
  },
];
