import { roleAdmin, roleSuperAdmin } from '@/mixins/roles';

const Managers = () => import('@/pages/managers.vue');

export default [
  {
    path: 'managers', // aka пользователи
    name: 'Managers',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Пользователи',
    },
    component: Managers,
  },
  {
    path: 'managers/:id',
    name: 'ManagersEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Пользователи',
    },
    component: Managers,
  },
];
