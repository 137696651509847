import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const ExcursionCategories = () => import('@/pages/excursionCategories.vue');

export default [
  {
    path: 'excursion-categories',
    name: 'ExcursionCategories',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Категории экскурсий',
    },
    component: ExcursionCategories,
  },
  {
    path: 'excursion-categories/:id',
    name: 'CategoriesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Категории экскурсий',
    },
    component: ExcursionCategories,
  },
];
