import standardAPI from '@/api/standard_items';

const state = {
  currencies: [],
  currencies_status: '',
  currencies_MetaData: {},
  currencies_page: 1,
  currencies_perPage: 10,
};

const responseUrl = '/currencies';

const getters = {
  get_currencies: (state) => {
    return state.currencies;
  },
  get_currencies_status: (state) => {
    return state.currencies_status;
  },
};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },

  fetch_currencies ({ commit }, getOptions) {
    commit('CURRENCIES_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('CURRENCIES_SUCCESS', response);
            commit('CURRENCIES_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('CURRENCIES_READY');
            reject(response);
          } else {
            commit('CURRENCIES_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },

  fetch_currencies_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },

  add_currency ({ commit, dispatch }, itemData) {
    commit('CURRENCIES_REQUEST');
    const preparedDate = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedDate, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('CURRENCIES_READY');
          reject(response);
        }
      });
    });
  },

  edit_currency ({ commit, dispatch }, itemData) {
    commit('CURRENCIES_REQUEST');
    const preparedDate = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedDate, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('CURRENCIES_READY');
          reject(response);
        }
      });
    });
  },

  remove_currency ({ commit, dispatch }, itemData) {
    commit('CURRENCIES_REQUEST');
    const preparedDate = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedDate, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('CURRENCIES_READY');
          reject(response);
        }
      });
    });
  },

  reorder_currency ({ commit, dispatch }, orderData) {
    const preparedDate = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedDate, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.currencies_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.currencies_perPage = max;
  },
  CURRENCIES_READY: (state) => {
    state.currencies_status = 'ready';
  },
  CURRENCIES_REQUEST: (state) => {
    state.currencies_status = 'loading';
  },
  CURRENCIES_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.currencies_MetaData.meta = response.meta;
      state.currencies_MetaData.links = response.links;
    }
    state.currencies = response.data;
    state.currencies_status = 'success';
  },
  CURRENCIES_ERROR: (state) => {
    state.currencies_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
