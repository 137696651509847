import standardAPI from '@/api/standard_items';

const state = {};

const getters = {};

const actions = {
  upload_image ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      url: '/upload-file/temp',
    };
    return new Promise((resolve, reject) => {
      standardAPI.upload_file(preparedData, (response) => {
        if (response.id) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  upload_file ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      url: '/upload-file/tinymce',
    };
    return new Promise((resolve, reject) => {
      standardAPI.upload_file(preparedData, (response) => {
        if (response.status && response.status === 'uploaded') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  universal_response ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      method: itemData.method || 'put' || 'post',
      url: itemData.url,
    };
    return new Promise((resolve, reject) => {
      standardAPI.universal_response(preparedData, (response) => {
        if (response.status && response.status !== 403) {
          if (response.status === 'reorder' || response.status === 'success' || response.status === 200) {
            resolve(response);
          }
        } else if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
