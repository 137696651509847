import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsProcedures = () => import('@/pages/hotels/procedures.vue');

export default [
  {
    path: '/hotels/procedures',
    name: 'HotelsProcedures',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Процедуры',
      ],
    },
    component: HotelsProcedures,
  },
  {
    path: '/hotels/procedures/:id',
    name: 'HotelsProceduresForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Процедуры',
      ],
    },
    component: HotelsProcedures,
  },
];
