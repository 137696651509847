import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const TeamCategories = () => import('@/pages/Team/categories.vue');

export default [
  {
    path: 'team/categories',
    name: 'TeamCategories',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Команда',
        'Подразделения',
      ],
    },
    component: TeamCategories,
  },
  {
    path: 'team/categories/:id',
    name: 'TeamCategoriesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Команда',
        'Подразделения',
      ],
    },
    component: TeamCategories,
  },
];
