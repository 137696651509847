import { roleAdmin, roleEditor, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsHotels = () => import('@/pages/hotels/hotels.vue');

export default [
  {
    path: '/hotels/hotels',
    name: 'HotelsHotels',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'SPA',
      ],
    },
    component: HotelsHotels,
  },
  {
    path: '/hotels/hotels/:id',
    name: 'HotelsHotelsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'SPA',
      ],
    },
    component: HotelsHotels,
  },
];
