import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Offices = () => import('@/pages/offices.vue');

export default [
  {
    path: 'offices',
    name: 'Offices',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Офисы',
    },
    component: Offices,
  },
  {
    path: 'offices/:id',
    name: 'OfficesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Офисы',
    },
    component: Offices,
  },
];
