import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursCountries = () => import('@/pages/world-tours/countries.vue');

export default [
  {
    path: '/world-tours/countries',
    name: 'WorldToursCountries',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Страны',
      ],
    },
    component: WorldToursCountries,
  },
  {
    path: '/world-tours/countries/:id',
    name: 'WorldToursCountriesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Страны',
      ],
    },
    component: WorldToursCountries,
  },
];
