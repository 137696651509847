<template>
  <div class="page">
    <transition name="fade">
      <div
        v-show="showSaveOverlay"
        class="overlay"
        data-title="Идет сохранение..."
      />
    </transition>

    <div class="page__content">
      <el-main>
        <div class="el-main__content">
          <router-view
            v-slot="{ Component, route }"
            :router-props="router_options"
            class="page__content-wrapper"
            @openSaveOverlay="toggleSaveOverlay"
            @updateOrders="updateOrders"
            @managersWithOrders="managersWithOrders"
          >
            <component
              :is="Component"
              :key="route.path"
            />
          </router-view>
        </div>
      </el-main>
    </div>

    <right-bar
      ref="rightBar"
      @openModal="openSearchModal"
    />

    <modal-search
      v-model="modals.showSearchModal"
    />
  </div>
</template>

<script>
import ModalSearch from '@/components/ModalSearch.vue';
import RightBar from '@/components/BarRight.vue';

export default {
  components: {
    ModalSearch,
    RightBar,
  },

  data () {
    return {
      showSaveOverlay: false,

      router_options: '',

      orderFilterStatus: 'ready',

      modals: {
        showSearchModal: false,
      },
    };
  },

  mounted () {
    function stickModal (elements) {
      function getCurrentYPosition () {
        return window.pageYOffset || document.documentElement.scrollTop;
      }

      if (elements && elements.length) {
        const top = getCurrentYPosition() + 'px';
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.top = top;
        }
      }
    }

    const modals = document.getElementsByClassName('standard-modal');

    let timer = null;
    window.addEventListener(
      'scroll',
      () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          stickModal(modals);
        }, 50);
      },
      false
    );
  },

  methods: {
    toggleSaveOverlay (show) {
      this.showSaveOverlay = show;
    },

    updateOrders () {
      this.$refs.rightBar.updateOrders();
    },

    managersWithOrders (managers) {
      this.$emit('managersWithOrders', managers);
    },

    isEqual (obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },

    filterOrdersBy (option) {
      if (this.orderFilterStatus === 'ready') {
        if (this.router_options === option || this.isEqual(this.router_options, option)) {
          this.router_options = '';
          this.activeOrdersFilter = false;
          this.expiredOrdersFilter = false;
          this.$emit('setManagersFilter', null);
        } else {
          if (option === 'filter-active') {
            this.activeOrdersFilter = true;
            this.expiredOrdersFilter = false;
            this.$emit('setManagersFilter', null);
          }
          if (option === 'filter-expired') {
            this.activeOrdersFilter = false;
            this.expiredOrdersFilter = true;
            this.$emit('setManagersFilter', null);
          }

          this.router_options = option;
          this.$router.push({ name: 'Orders' });
        }
      }
    },

    openSearchModal () {
      this.modals.showSearchModal = true;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.page-content-overlay {
  display: none !important;

}

.page {
  width: 100%;
  background-color: #f4f3ef;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: url('../../public/static/img/background/background_patern.png') repeat;
    background-size: 80%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.07;

    @media (max-width: 768px) {
      background-size: 260%;
    }
  }

  &__content {
    padding-right: 65px;
    position: relative;
    z-index: 2;

    &-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-inner {
      overflow-y: auto;
    }
  }

  &__filter {
    padding: 0 10px 10px;

    &-fields {
      display: flex;
      flex-wrap: wrap;
      margin: -12px -6px 0;
    }

    &-field {
      margin: 12px 6px 0;
      width: calc(20% - 12px);

      &--12 {
        width: calc(12% - 12px);
      }

      &--14 {
        width: calc(16.66% - 12px);
      }

      &--25 {
        width: calc(25% - 12px);
      }

      &--33 {
        width: calc(33.33% - 12px);
      }

      &--40 {
        width: calc(40% - 12px);
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 100%;
      }
    }

    &-actions {
      margin-top: 10px;
    }
  }
}


.el-main {
  padding: 15px;
  height: 100vh;
  position: relative;

  &__content {
    height: 100%;
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(#232322, .86);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &::before {
    content: attr(data-title);
    display: block;
    color: #fff;
    font-size: 28px;
  }
}
</style>
