import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsCountries = () => import('@/pages/hotels/countries.vue');

export default [
  {
    path: '/hotels/countries',
    name: 'HotelsCountries',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Страны',
      ],
    },
    component: HotelsCountries,
  },
  {
    path: '/hotels/countries/:id',
    name: 'HotelsCountriesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Страны',
      ],
    },
    component: HotelsCountries,
  },
];
