import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Subscribers = () => import('@/pages/subscribers.vue');

export default [
  {
    path: 'subscribers',
    name: 'Subscribers',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Подписчики',
    },
    component: Subscribers,
  },
];
