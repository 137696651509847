import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Currencies = () => import('@/pages/currencies.vue');

export default [
  {
    path: 'currencies',
    name: 'Currencies',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Валюты',
    },
    component: Currencies,
  },
  {
    path: 'currencies/:id',
    name: 'CurrenciesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Валюты',
    },
    component: Currencies,
  },
];
