import _isNull from 'lodash/isNull';

export default {
  methods: {
    responseNotify (response, type, title, titleMsg = '') {
      let typeMsg = '';
      switch (type) {
        case 'error':
          typeMsg = 'Ошибка! ';
          break;
        case 'warning':
          typeMsg = 'Внимание! ';
          break;
        case 'success':
          typeMsg = '';
          break;
      }

      let itemMsg = title.toLowerCase();
      itemMsg = itemMsg.charAt(0).toUpperCase() + itemMsg.slice(1);
      if (response instanceof Object) {
        let text = '';
        for (const value in response) {
          if (response[value] instanceof Array) {
            for (let i = 0; i < response[value].length; i++) {
              text = text + response[value][i] + '<br />';
            }
          }
        }
        text = text.slice(0, text.length - 2);
        this.$globNotify(this, type, text, ' ' + typeMsg + itemMsg + ' ' + titleMsg);
      } else {
        this.$globNotify(this, type, ' ' + typeMsg + itemMsg + ' ' + titleMsg);
      }
    },

    isInViewport (el, offset = 0) {
      if (_isNull(el)) return false;
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= offset &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    getScrollParent (el) {
      if (_isNull(el)) return window;
      if (el.scrollHeight > el.clientHeight) {
        return el;
      }
      return this.getScrollParent(el.parentNode);
    },

    scrollTo (el, offset = 80) {
      if (_isNull(el)) return;
      const container = this.getScrollParent(el.parentNode);
      container.scrollTo({
        top: (container.scrollTop ? container.scrollTop : 0) + el.getBoundingClientRect().y - offset,
        behavior: 'smooth',
      });
    },

    scrollToIfNeed (el, offset = 80) {
      if (_isNull(el) || this.isInViewport(el, offset)) return;
      this.scrollTo(el, offset);
    },
  },
};
