export default {
  install (app, _options) {
    app.config.globalProperties.$globNotify = (
      context,
      type,
      message = null,
      title = null
    ) => {
      switch (type) {
        case 'error':
          context.$notify({
            type: 'danger',
            title: title,
            text: message || 'Ошибка!',
            speed: 300,
            duration: 10000 - 500,
          });
          break;
        case 'success':
          context.$notify({
            type: 'success',
            title: title,
            text: message || 'Операция успешна',
            speed: 300,
            duration: 3000 - 500,
          });
          break;
        case 'warning':
        default:
          context.$notify({
            type: 'warning',
            title: title,
            text: message,
            speed: 300,
            duration: 5000 - 500,
          });
          break;
      }
    };
  },
};
