import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursAttractions = () => import('@/pages/world-tours/attractions.vue');

export default [
  {
    path: '/world-tours/attractions',
    name: 'WorldToursAttractions',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Достопримечательности',
      ],
    },
    component: WorldToursAttractions,
  },
  {
    path: '/world-tours/attractions/:id',
    name: 'WorldToursAttractionsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Достопримечательности',
      ],
    },
    component: WorldToursAttractions,
  },
];
