import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const ExcursionTypes = () => import('@/pages/excursionTypes.vue');

export default [
  {
    path: 'excursion-types',
    name: 'ExcursionTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы экскурсий',
    },
    component: ExcursionTypes,
  },
  {
    path: 'excursion-types/:id',
    name: 'ExcursionTypesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы экскурсий',
    },
    component: ExcursionTypes,
  },
];
