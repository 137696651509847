import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Regions = () => import('@/pages/regions.vue');

export default [
  {
    path: 'regions',
    name: 'Regions',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Регионы',
    },
    component: Regions,
  },
  {
    path: 'regions/:id',
    name: 'RegionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Регионы',
    },
    component: Regions,
  },
];
