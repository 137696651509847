import { roleAdmin, roleSuperAdmin, roleManager, roleOperator } from '@/mixins/roles';

const Agents = () => import('@/pages/agents.vue');

export default [
  {
    path: 'agents',
    name: 'Agents',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Агенты',
    },
    component: Agents,
  },
  {
    path: 'agents/:id',
    name: 'AgentsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Агенты',
    },
    component: Agents,
  },
];
