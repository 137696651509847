import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import commonHelper from '@/mixins/common-helper';

const priceListActionsOperations = {
  mixins: [
    commonHelper,
  ],

  data () {
    return {
      formDataName: '',
      requiredProperties: [],
      isChanged: false,
      weekDays: [
        { id: '1', name: 'вс' },
        { id: '2', name: 'пн' },
        { id: '3', name: 'вт' },
        { id: '4', name: 'ср' },
        { id: '5', name: 'чт' },
        { id: '6', name: 'пт' },
        { id: '7', name: 'сб' },
      ],
      pricesList: [],
      showConfirmDialog: false,
    };
  },

  watch: {
    priceTypesList: {
      handler (val) {
        this.pricesList = val.map((a) => Object.assign({}, a));
      },
    },
  },

  methods: {
    isNotEmpty (value) {
      const str = value + '';
      return !!str.replace(/\s/g, '').length;
    },

    getPointCategoryFromGroupedPrices (priceRow) {
      return _get(priceRow, '0.category');
    },

    groupPrices (prices) {
      return _groupBy(prices, (priceItem) => {
        return _get(priceItem, 'category.id');
      });
    },

    currencyChange (data) {
      if (!this.isChanged) {
        this.isChanged = true;
      }
      // set currency to all current group price types
      for (let i = 0; i < data.pricesGroup.length; i++) {
        data.pricesGroup[i].category.currency_id = data.value;
      }
    },

    preparePrices (priceItems) {
      // Підготовка цін для збереження в прайслисті
      try {
        const resultArray = [];

        for (let j = 0; j < priceItems.length; j++) {
          if (priceItems[j].price) {
            if (this.isNotEmpty(priceItems[j].price)) {
              const resultItem = {};
              resultItem.pc_cat_id = _get(priceItems[j], 'category.id');
              resultItem.pt_id = _get(priceItems[j], 'id');
              resultItem.currency_id = _get(priceItems[j], 'category.currency_id');
              resultItem.comment_price = _get(priceItems[j], 'default_comment');
              resultItem.price = priceItems[j].price;

              resultArray.push(resultItem);
            }
          }
        }
        return resultArray;
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default priceListActionsOperations;
