import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles'

const ExcursionDirections = () => import('@/pages/excursionDirections.vue');

export default [
  {
    path: 'excursion-directions',
    name: 'ExcursionDirections',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Направления экскурсий',
    },
    component: ExcursionDirections,
  },
  {
    path: 'excursion-directions/:id',
    name: 'ExcursionDirectionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Направления экскурсий',
    },
    component: ExcursionDirections,
  },
];
