import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsParameters = () => import('@/pages/hotels/parameters.vue');

export default [
  {
    path: '/hotels/parameters',
    name: 'HotelsParameters',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Параметры',
      ],
    },
    component: HotelsParameters,
  },
  {
    path: '/hotels/parameters/:id',
    name: 'HotelsParametersForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Параметры',
      ],
    },
    component: HotelsParameters,
  },
];
