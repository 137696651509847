<template>
  <el-container style="height: 100vh">
    <el-aside
      :width="`${defaultLabelWidth}px`"
      :class="{ 'el-aside--mini': sidebarCollapsed }"
    >
      <a
        href="/"
        class="logo"
      >
        <img
          src="/static/img/logo_atlantis.svg"
          alt="Atlantis Travel"
        >
      </a>
      <a
        href="javascript:{}"
        class="toggle"
        @click="sidebarCollapsed = !sidebarCollapsed"
      >
        <el-icon>
          <icon-arrow />
        </el-icon>
      </a>
      <div
        v-if="$auth.user()"
        class="user"
      >
        <span class="user__name">{{ $auth.user().name }}</span>
        <a
          href="javascript:{}"
          class="user__logout"
          @click="logout"
        >
          <i class="icomoon icon-logout" />
        </a>
      </div>

      <ul
        v-if="showManagers && managersWithOrdersList.length"
        class="managers"
      >
        <li
          v-for="(manager, managerKey) in managersWithOrdersList"
          :key="managerKey"
          :class="{ active: manager.id === filterManagerId }"
        >
          <a
            href="javascript:{}"
            @click.prevent="filterOrdersBy({ appointedManagerId: manager.id })"
          >
            {{ manager.last_name + ' ' + manager.first_name[0] }}
          </a>
          <span class="green">{{ manager.countOrders }}</span>
          <span class="red">{{ manager.countExpiredOrders }}</span>
        </li>
      </ul>

      <el-scrollbar>
        <el-menu
          :router="true"
          :default-active="activeLink"
        >
          <template v-for="(link, linkIndex) in navigation">
            <template v-if="link.children">
              <el-sub-menu
                :key="linkIndex"
                :index="`link-${linkIndex}`"
              >
                <template #title>
                  <i
                    v-if="link.icon"
                    :class="link.icon"
                  />
                  <span
                    v-else
                    class="short"
                    v-text="link.name[0]"
                  />
                  <span
                    class="full"
                    v-html="link.name"
                  />
                </template>
                <el-menu-item
                  v-for="(sublink, sublinkIndex) in link.children"
                  :key="`sublink-${sublinkIndex}`"
                  :index="sublink.path"
                >
                  <router-link :to="sublink.path" />
                  <i
                    v-if="sublink.icon"
                    :class="sublink.icon"
                  />
                  <span
                    v-else
                    class="short"
                    v-text="sublink.name[0]"
                  />
                  <span
                    class="full"
                    v-html="sublink.name"
                  />
                </el-menu-item>
              </el-sub-menu>
            </template>
            <template v-else>
              <el-menu-item
                :key="linkIndex"
                :index="`${link.path ? link.path : `link-${linkIndex}`}`"
              >
                <router-link :to="link.path" />
                <i
                  v-if="link.icon"
                  :class="link.icon"
                />
                <span
                  v-else
                  class="short"
                  v-text="link.name[0]"
                />
                <span
                  class="full"
                  v-html="link.name"
                />
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>

    <el-container>
      <dashboard-content
        ref="DashboardContent"
        @setManagersFilter="filterOrdersBy"
        @managersWithOrders="managersWithOrders"
      />
    </el-container>
  </el-container>
</template>

<script>
import DashboardContent from '@/components/PageContent.vue';
import sidebarLinks from '@/constants';
import { roles } from '@/mixins';
import { ArrowLeft as IconArrow } from '@element-plus/icons-vue';

export default {
  components: {
    DashboardContent,
    IconArrow,
  },
  mixins: [roles],
  inject: ['defaultLabelWidth'],

  data () {
    return {
      sidebarCollapsed: false,
      managersWithOrdersList: [],
      showManagers: true,
      filterManagerId: null,
      activeLink: null,
    };
  },

  computed: {
    authRole () {
      return this.$auth.user() ? this.roles[this.$auth.user().role] : false;
    },

    navigation () {
      let result = [];
      if (this.authRole === 'superAdmin' || this.authRole === 'admin') {
        result = sidebarLinks;
      } else {
        const _this = this;
        for (let i = 0; i < sidebarLinks.length; i++) {
          const subLinks = { name: sidebarLinks[i].name };
          if (sidebarLinks[i].children) {
            subLinks.children = sidebarLinks[i].children.filter((link) =>
              link.roles ? link.roles.some((role) => role === _this.authRole) : true
            );
          }
          if (subLinks.children && subLinks.children.length) {
            result.push(subLinks);
          }
        }
      }
      return result;
    },
  },

  watch: {
    $route: {
      handler (route) {
        this.showManagers = route.matched.length && route.path === '/orders';
        this.activeLink = route.path;
      },
    },
  },

  mounted: function () {
    this.activeLink = this.$route.path;
  },

  methods: {
    managersWithOrders (managers) {
      this.managersWithOrdersList = managers;
    },

    logout () {
      this.$auth
        .logout({
          redirect: '/login',
        })
        .then((_response) => {
          this.$notify({
            type: 'warning',
            message: 'Вы успешно вышли из своего аккаунта',
          });
        })
        .catch((_response) => {
          this.$notify({
            type: 'danger',
            message: 'выход не удался',
            timeout: 0,
          });
        });
    },

    filterOrdersBy (option) {
      if (option) {
        if (this.$refs.DashboardContent.orderFilterStatus === 'ready') {
          this.filterManagerId = option.appointedManagerId;
          this.$refs.DashboardContent.filterOrdersBy({ appointedManagerId: option.appointedManagerId });
        }
      } else {
        this.filterManagerId = null;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
$animationSpeed: 0s;

.el-aside {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: #bdbdbc;
  background: #212120;
  position: relative;
  z-index: 11;
  transition: width $animationSpeed;

  &--mini {
    width: 60px !important;

    .toggle .el-icon {
      transform: rotate(180deg);
    }

    .logo {
      visibility: hidden;
    }

    .user__logout {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

    .el-sub-menu__title,
    .el-menu-item {
      text-indent: -9999px;

      .icomoon {
        text-indent: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .short {
        display: block !important;
        text-indent: 0 !important;
      }

      .full {
        display: none !important;
      }
    }
  }

  .logo {
    padding: 18px 11px 15px;
    height: 90px;
  }

  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #ffffff;
    position: absolute;
    top: 25px; // 90/2 - 40/2
    right: 10px;
    font-size: 20px;
  }

  .user {
    padding: 15px 45px 15px 15px;
    color: #ffffff;
    background: #eb7e23;
    position: relative;

    &__name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__logout {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      color: #ffffff;
      text-decoration: none;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }

  .managers {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #4a4a4a;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 15px;
      font-size: 14px;

      &.active {
        a {
          color: #eb7e23;
        }
      }

      a {
        margin-right: auto;
        max-width: calc(100% - 48px);
        text-decoration: none;
        font-weight: bold;
        color: #ffffff;
      }

      span {
        display: block;
        margin-left: 2px;
        border: 1px solid transparent;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        color: #ffffff;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        transition: all $animationSpeed;

        &:hover {
          border-color: #ffffff;
        }

        &.red {
          background: #ff0000;
        }

        &.green {
          background: #46a546;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-menu {
  border-right: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background: transparent;
  &[style="--el-menu-level: 0;"] > .el-sub-menu + .el-sub-menu,
  &[style="--el-menu-level: 0;"] > .el-sub-menu + .el-menu-item,
  &[style="--el-menu-level: 0;"] > .el-menu-item + .el-sub-menu,
  &[style="--el-menu-level: 0;"] > .el-menu-item + .el-menu-item {
    position: relative;

    &::after {
      content: '';
      display: block;
      border-top: 1px solid #bdbdbc;
      position: absolute;
      top: 0;
      left: calc(var(--el-menu-level) * var(--el-menu-level-padding));
      right: calc(var(--el-menu-level) * var(--el-menu-level-padding));
    }
  }

  .el-menu {
    margin-bottom: 12px;
  }
}

.el-sub-menu__title,
.el-menu-item {
  height: auto !important;
  line-height: initial !important;
  color: #bdbdbc;
  position: relative;

  > a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:hover {
    color: #fff;
    background: transparent;
  }

  &.is-active {
    color: #eb7e23;
  }

  .icomoon {
    display: block;
    position: absolute;
    top: 50%;
    left: calc(var(--el-menu-level) * var(--el-menu-level-padding));
    transform: translateY(-50%);
  }

  .short {
    display: none;
  }
  .full {
    display: block;
  }
}

.el-sub-menu.is-active > .el-sub-menu__title {
  color: #eb7e23;
}

.el-sub-menu__title {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 46px;
}
.el-menu-item {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 38px;

  &.is-active {
    position: relative;

    &::before {
      content: '';
      display: block;
      margin-top: -10px;
      border-width: 15px 15px 15px 0;
      border-style: solid;
      border-color: transparent #f4f3ef;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      right: 0;
    }
  }
}
</style>
