import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles'

const ToursDirections = () => import('@/pages/toursDirections.vue');

export default [
  {
    path: 'tours-directions',
    name: 'ToursDirections',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Направления экскурсий',
    },
    component: ToursDirections,
  },
  {
    path: 'tours-directions/:id',
    name: 'ToursDirectionsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Направления экскурсий',
    },
    component: ToursDirections,
  },
];
