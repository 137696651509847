import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsCities = () => import('@/pages/hotels/cities.vue');

export default [
  {
    path: '/hotels/cities',
    name: 'HotelsCities',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Города',
      ],
    },
    component: HotelsCities,
  },
  {
    path: '/hotels/cities/:id',
    name: 'HotelsCitiesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Города',
      ],
    },
    component: HotelsCities,
  },
];
