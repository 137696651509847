<template>
  <div class="right-bar">
    <button
      v-if="hasAccess"
      class="right-bar__button right-bar__button--green"
      :class="{ active: activeOrdersFilter }"
      @click="filterOrdersBy('filter-active')"
    >
      {{ myOrders }}
    </button>
    <button
      v-if="hasAccess"
      class="right-bar__button right-bar__button--red"
      :class="{ active: expiredOrdersFilter }"
      @click="filterOrdersBy('filter-expired')"
    >
      {{ myExpiredOrders }}
    </button>
    <button
      v-if="hasAccess"
      class="right-bar__button right-bar__button--last"
      @click="$emit('openModal')"
    >
      <i class="icomoon icon-agents2" />
    </button>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { roles } from '@/mixins';

export default {
  mixins: [roles],

  emits: ['openModal'],

  setup () {
    const store = useStore();
    return {
      getRules: (options) => { return store.dispatch('config_rules/fetch_config_rules', options); },
      orderOperations: (options) => { return store.dispatch('orders/order_operations', options); },
    };
  },

  data () {
    return {
      activeOrdersFilter: 0,
      expiredOrdersFilter: 0,
      myOrders: 0,
      myExpiredOrders: 0,
    };
  },

  computed: {
    hasAccess () {
      if (this.$auth.user()) {
        const role = this.roles[this.$auth.user().role];
        return role !== 'holiday';
      }
      return false;
    },
  },

  watch: {
    $route: {
      handler (route) {
        if (route.matched.length && this.hasAccess) {
          this.updateOrders();
        }
      },
    },
  },

  created () {
    this.getRules({ token: this.$auth.token() }).catch(() => {
      this.$globNotify(this, 'error', 'Ошибка получения кофигурации!');
    });

    if (this.hasAccess) {
      this.updateOrders();
    }
  },

  methods: {
    fetchOrdersCount () {
      this.orderOperations({
        token: this.$auth.token(),
        url: '/orders/static/count-my-orders',
        method: 'get',
      })
        .then((response) => {
          this.myOrders = response.data.count;
        })
        .catch((_response) => {
          this.$globNotify(this, 'error', 'Ошибка получения количества ваших заказов');
        });
    },

    fetchExpiredOrdersCount () {
      this.orderOperations({
        token: this.$auth.token(),
        url: '/orders/static/count-my-expired-orders',
        method: 'get',
      })
        .then((response) => {
          this.myExpiredOrders = response.data.count;
        })
        .catch((_response) => {
          this.$globNotify(this, 'error', 'Ошибка получения количества ваших просроченных заказов');
        });
    },

    updateOrders () {
      this.fetchOrdersCount();
      this.fetchExpiredOrdersCount();
    },

    filterOrdersBy (option) {
      if (this.orderFilterStatus === 'ready') {
        if (this.router_options === option || this.isEqual(this.router_options, option)) {
          this.router_options = '';
          this.activeOrdersFilter = false;
          this.expiredOrdersFilter = false;
          this.$emit('setManagersFilter', null);
        } else {
          if (option === 'filter-active') {
            this.activeOrdersFilter = true;
            this.expiredOrdersFilter = false;
            this.$emit('setManagersFilter', null);
          }
          if (option === 'filter-expired') {
            this.activeOrdersFilter = false;
            this.expiredOrdersFilter = true;
            this.$emit('setManagersFilter', null);
          }

          this.router_options = option;
          this.$router.push({ name: 'Orders' });
        }
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.right-bar {
  display: flex;
  flex-direction: column;
  align-items: unset;
  margin: 0;
  padding: 15px;
  width: 65px;
  height: 100vh;
  background-color: #232322cc;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
    border: 2px solid transparent;
    border-radius: 50%;
    padding: 0;
    width: 35px;
    height: 35px;
    color: #000;
    background: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 29px;
    outline: 0;
    cursor: pointer;
    transition: all 0.25s;
    opacity: 0.8;

    &:hover {
      border-color: #fff;
      opacity: 1;
    }

    &--green,
    &--red {
      color: #fff;
      opacity: 1;
    }

    &--green {
      background: #46a546;
    }

    &--red {
      background: #f00;
    }

    &--last {
      margin-top: auto;
      margin-bottom: 0;
    }

    .icomoon {
      font-size: 20px;
    }
  }
}
</style>
