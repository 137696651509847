import { configure, defineRule } from 'vee-validate';
import veeValidateLocaleRu from '@vee-validate/i18n/dist/locale/ru.json';
import { localize, setLocale } from '@vee-validate/i18n';
import * as veeValidateRules from '@vee-validate/rules';

Object.keys(veeValidateRules).forEach(rule => {
  if (typeof veeValidateRules[rule] === 'function') {
    defineRule(rule, veeValidateRules[rule]);
  }
});

configure({
  generateMessage: localize({
    ru: veeValidateLocaleRu,
  }),
});
setLocale('ru');
