const pageOptionsOperations = {
  data () {
    return {
      pageOptions: {
        max: null,
        page: null,
        q: '',
        orderByColumn: '',
        orderByMethod: '',
      },
    };
  },

  methods: {
    sortBy (column, order) {
      this.pageOptions.orderByColumn = column;
      switch (order) {
        case 'ascending':
          this.pageOptions.orderByMethod = 'asc';
          break;
        case 'descending':
          this.pageOptions.orderByMethod = 'desc';
          break;
        default:
          this.pageOptions.orderByMethod = '';
          break;
      }
      this.fetchData();
    },

    searchQuery () {
      this.pageOptions.page = null;
      this.setCurrentPage(1);
      this.fetchData();
    },

    changePerPage: function (perPage) {
      this.pageOptions.page = null;
      this.pageOptions.max = perPage;
      this.setCurrentPage(1);
      this.setPerPage(perPage);
      this.fetchData();
    },

    changePage: function (pageNumber) {
      this.pageOptions.page = pageNumber;
      this.setCurrentPage(pageNumber);
      this.fetchData();
    },
  },

  created () {
    // console.log('created mix')
    this.pageOptions.max = this.per_page;
    this.pageOptions.page = this.current_page;
  },
};

export default pageOptionsOperations;
