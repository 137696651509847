<template>
  <div class="wrapper">
    <div class="wrapper__content">
      <v-form @submit="onSubmit">
        <h1>Войти</h1>
        <input-with-validation
          v-model="formData.userEmail"
          label="Логин"
          placeholder="Логин"
          label-position="hidden"
          name="email"
          rules="email|required"
          type="email"
        />
        <input-with-validation
          v-model="formData.password"
          label="Пароль"
          placeholder="Пароль"
          label-position="hidden"
          name="password"
          rules="required"
          type="password"
        />
        <el-button
          :loading="loading"
          type="primary"
          native-type="submit"
          round
        >
          НАЧАТЬ
        </el-button>
      </v-form>
    </div>
    <div
      class="wrapper__background"
      style="background-image: url(/static/img/background/background_login.jpg)"
    />
  </div>
</template>

<script>
import { Form as VForm } from 'vee-validate';
import InputWithValidation from '@/components/forms/input.vue';

export default {
  components: {
    InputWithValidation,
    VForm,
  },

  data () {
    return {
      loading: false,
      formData: {
        userEmail: '',
        password: '',
      },
    };
  },

  methods: {
    onSubmit (values) {
      this.loading = true;
      this.$auth.login({
        body: values,
        data: values,
        rememberMe: true,
        fetchUser: true,
      })
        .then((_response) => {
          this.$globNotify(this, 'success', 'Вы вошли как: ' + this.$auth.user().name);
        })
        .catch((_response) => {
          this.$globNotify(this, 'error', 'Вы ввели неправильный email или пароль');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style
 lang="scss"
 scoped
>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
  min-height: 100vh;
  position: relative;

  &__content {
    border-radius: 6px;
    padding: 1rem;
    width: 100%;
    max-width: 340px;
    background-color: #fff;
    box-shadow: 0 6px 10px -4px rgba(#000, 0.15);
    position: relative;
    z-index: 3;
  }

  &::after,
  &__background{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(#000, 0.7)
  }

  &::after {
    content: '';
    z-index: 2;
  }

  &__background {
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  h1 {
    margin: 0 0 1rem;
    font-weight: normal;
    text-align: center;
    font-size: 28px;
  }

  .el-button {
    width: 100%;
  }
}
</style>
