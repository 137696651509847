import {
  commonHelper,
  findItem
} from '@/mixins';
import { languages } from '@/constants';

const itemsOperations = {
  mixins: [
    commonHelper,
    findItem,
  ],

  data () {
    return {
      changeItemOrderStatus: false,
      changeItemActiveStatus: false,
      itemsData: [],
      checkedItems: [],
      showModal: false,
      waitModal: null,
    };
  },

  methods: {
    closeModal () {
      this.showModal = false;
      this.item_id = null;
      if (this.waitModal) {
        clearInterval(this.waitModal);
        this.waitModal = null;
      }
    },

    getItemsId (item, property) {
      const result = [];
      if (item[property] && item[property].length) {
        for (let i = 0; i < item[property].length; i++) {
          if (item[property][i].id) {
            result.push(item[property][i].id);
          }
        }
      }
      return result;
    },

    prepareGetData (options) {
      const resultData = {
        token: this.$auth.token(),
        pageOptions: {},
        payload: {},
      };
      if (options) {
        if (options.pageOptions) {
          for (const key in options.pageOptions) {
            resultData.pageOptions[key] = options.pageOptions[key];
          }
        }
        if (options.getAll === true) {
          resultData.pageOptions.max = -1;
        }
        if (options.payload) {
          for (const key in options.payload) {
            resultData.payload[key] = options.payload[key];
          }
        }
      }
      return resultData;
    },

    fetchData () {
      this.getData(this.prepareGetData({ pageOptions: this.pageOptions }))
        .then((data) => {
          if (data.data) {
            this.itemsData = data.data;
          } else {
            this.itemsData = data;
          }
        })
        .catch(() => {
          this.$globNotify(this, 'error', `Ошибка получения ${this.notifyText[2]}!`);
        });
    },

    newItem () {
      this.toggleEditModal('new');
    },
    editItem (item) {
      this.toggleEditModal('edit', item);
    },

    removeItems () {
      if (this.checkedItems.length) {
        const itemData = {
          checkedIds: { ids: this.checkedItems },
          token: this.$auth.token(),
        };
        this.deleteItems(itemData)
          .then(() => {
            this.fetchData();
            this.afterRemove();
            this.$globNotify(this, 'success', `${this.notifyText[1]} успешно удалены`);
          })
          .catch((response) => {
            this.$globNotify(this, response.status ? response.status : 'error', response.message ? response.message : 'Ошибка удаления');
          });
      } else {
        this.$globNotify(this, 'warning', `Не выбран ${this.notifyText[0]} для удаления`);
      }
    },

    saveData (settings) {
      settings = this.beforeSave(settings);
      this.deleteFormDataWatcher();
      if (this.itemsStatus === 'ready') {
        this.$emit('openSaveOverlay', true);
        if (settings.actionType === 'new') {
          this.addItem(this.prepareFormData())
            .then((response) => {
              this.$emit('openSaveOverlay', false);
              if (response.data) {
                // we need to setForm & fetchData after addItem only if we got new item
                this.afterSave(response.data);
                try {
                  this.setForm(response.data);
                } catch (e) {
                  console.warn('Can\'t setForm after addItem');
                }
                this.fetchData();
              }
              this.$globNotify(this, 'success', `${this.notifyText[0]} успешно создан(а)`);
              if (settings.closeModal) {
                this.closeModal();
              } else {
                this.modalSettings.actionType = 'edit';
                if (response.data) {
                  this.item_id = response.data.id;
                }
                this.addFormDataWatcher();
              }
            })
            .catch((response) => {
              this.$emit('openSaveOverlay', false);
              this.responseNotify(response, 'error', this.notifyText[0], 'не создан(а)');
            });
        }

        if (settings.actionType === 'edit') {
          this.modifyItem(this.prepareFormData())
            .then((response) => {
              this.$emit('openSaveOverlay', false);
              if (response.data) {
                // we need to setForm & fetchData after modifyItem only if we got updated item
                this.afterSave(response.data);
                try {
                  this.setForm(response.data);
                } catch (e) {
                  console.warn('Can\'t setForm after modifyItem');
                }
                this.fetchData();
              }
              this.$globNotify(this, 'success', `${this.notifyText[0]} успешно сохранен(а)`);
              if (settings.closeModal) {
                this.closeModal();
              } else {
                this.addFormDataWatcher();
              }
            })
            .catch((response) => {
              this.$emit('openSaveOverlay', false);
              this.responseNotify(response, 'error', this.notifyText[0], 'не сохранен(а)');
            });
        }
      } else {
        this.$globNotify(this, 'warning', 'Подождите пока обрабатывается предыдущий запрос');
      }
    },

    changeItemOrder (orderData) {
      const resultData = {
        orderData,
        token: this.$auth.token(),
      };
      this.reorderItem(resultData)
        .then((_response) => {
          if (this.findItemBy('priority', true, this.columns) !== undefined) {
            // Если в таблице есть колонка приоритета - перезагружаем список чтобы обновить её значениея
            // А если такой колонки нет то это не имеет смысла
            this.fetchData();
          }
        }).catch(() => {
          this.$globNotify(this, 'error', 'Ошибка запроса! Порядок не изменен');
        });
    },

    changeItemOrderNumber (order, item, field = 'display_order') {
      const value = parseInt(order, 10);
      if (!this.changeItemOrderStatus && Number.isInteger(value)) {
        if (value) {
          this.changeItemOrderStatus = item.id;
          const resultData = {
            id: item.id,
            token: this.$auth.token(),
            method: 'put',
            payload: {},
          };
          resultData.payload[field] = value;
          if (field === 'display_order') {
            resultData.payload.priority_order = value; // this need for 'pc-categories'
          }
          this.setItemOrder(resultData)
            .then((response) => {
              this.pageOptions.orderByColumn = 'display_order';
              this.fetchData();
            })
            .catch((_response) => {
              this.$globNotify(this, 'error', 'Ошибка запроса! Порядок не изменен');
            })
            .finally(() => {
              this.changeItemOrderStatus = false;
            });
        } else {
          this.$globNotify(this, 'warning', 'Введите целое число больше нуля');
        }
      } else {
        this.$globNotify(this, 'warning', 'Введите целое число');
      }
    },

    checkboxChange (checkedIds) {
      this.checkedItems = checkedIds;
    },

    beforeSave (settings) {
      return settings;
    },

    afterSave (_item) {},

    afterRemove () {},

    changeItemActive (item) {
      if (!this.changeItemActiveStatus) {
        this.changeItemActiveStatus = item.id;
        const responseData = {
          id: item.id,
          token: this.$auth.token(),
          payload: {
            id: item.id,
            is_active: item.is_active,
          },
        };
        this.activeItem(responseData).then((_response) => {})
          .then((_response) => {
            this.$globNotify(this, 'success', 'Статус изменен');
          })
          .catch((_response) => {
            this.$globNotify(this, 'error', 'Ошибка запроса! Статус не изменен');
          })
          .finally(() => {
            this.changeItemActiveStatus = null;
          });
      } else {
        this.$globNotify(this, 'warning', 'Подождите пока обрабатывается предыдущий запрос');
      }
    },

    setTranslatableField (formData, field, item = null, languageCodes = null) {
      if (!languageCodes) {
        languageCodes = Object.keys(languages);
      }
      languageCodes.forEach((languageCode) => {
        formData[`${field}_${languageCode}`] = item && item[`${field}_${languageCode}`] ? item[`${field}_${languageCode}`] : '';
      });
    },

    setTranslatableFields (formData, fields, item = null, languageCodes = null) {
      fields.forEach((field) => {
        this.setTranslatableField(formData, field, item, languageCodes);
      });
    },
  },

  beforeDestroy () {
    this.closeModal();
    document.body.classList.remove('overflowHidden');
  },
};

export default itemsOperations;
