<template>
  <el-drawer
    v-model="value"
    title="Агенты"
    direction="rtl"
  >
    <el-form-item>
      <el-input
        ref="query"
        v-model="pageOptions.q"
        class="input-sm search-input modal-search"
        placeholder="Поиск..."
        addon-right-icon="nc-icon nc-zoom-split"
        @keydown.enter="searchSubmit(pageOptions.q)"
        @keydown.esc="searchClean()"
      >
        <template #prefix>
          <el-icon class="el-input__icon">
            <icon-search />
          </el-icon>
        </template>
      </el-input>
    </el-form-item>

    <div class="content-container">
      <div v-if="agentsStatus === 'loading'">
        Поиск агентов...
      </div>
      <template v-if="agentsStatus === 'ready'">
        <ul
          v-if="agentsList.length"
          class="agents-list"
        >
          <li
            v-for="(agent, index) in agentsList"
            :key="index"
            class="agent-item"
          >
            <div class="agent-subitem agent-name">
              <i class="icomoon icon-agents" />
              <div class="name">
                {{ agent.first_name }} {{ agent.last_name }}
              </div>
            </div>
            <div class="agent-subitem agent-email">
              <i class="icomoon icon-mail" />
              <div class="email">
                <a :href="'mailto:' + agent.email">{{ agent.email }}</a>
              </div>
            </div>
            <div
              v-if="agent.phones.length"
              class="agent-subitem agent-phone"
            >
              <i class="icomoon agent-phone"><img
                src="../../public/static/img/smartphone.svg"
                alt="phone"
              ></i>
              <div>
                <div
                  v-for="(phone, phoneIndex) in agent.phones"
                  :key="`phone_${phoneIndex}`"
                  class="phone"
                >
                  +{{ phone.phoneCountryCode.phone_code }}{{ phone.phone_number }}
                </div>
              </div>
            </div>
            <div class="agent-subitem agent-agency">
              <i class="icomoon icon-agencies" />
              <div>
                <div class="agency">
                  {{ agent.agency ? agent.agency.title_ru : 'нет' }}
                </div>
                <div class="agency_city">
                  {{ agent.agency ? agent.agency.city_name_ru : 'нет' }}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-else>
          По текущему запросу агентов не найдено
        </div>
      </template>
    </div>
  </el-drawer>
</template>

<script>
import { mapActions } from 'vuex';
import { Search as IconSearch } from '@element-plus/icons-vue';
import _get from 'lodash/get';

export default {
  components: {
    IconSearch,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      agentsStatus: '',
      agentsList: [],
      pageOptions: {
        max: -1,
        q: '',
      },
    };
  },

  computed: {
    value: {
      get () {
        return this.modelValue;
      },
      set (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },

  watch: {
    value (val) {
      this.agentsStatus = '';
      this.agentsList = [];
      this.pageOptions.q = '';
      if (val) {
        setTimeout(() => {
          this.$refs.query.focus();
        }, 100);
      }
    },
  },

  methods: {
    ...mapActions({
      getAgents: 'agents/fetch_query_agents',
    }),

    prepareGetData (options) {
      const resultData = {
        token: this.$auth.token(),
        pageOptions: {},
        payload: {},
      };
      if (options) {
        if (options.pageOptions) {
          for (const key in options.pageOptions) {
            resultData.pageOptions[key] = options.pageOptions[key];
          }
        }
        if (options.getAll === true) {
          resultData.pageOptions = { max: -1 };
        }
        if (options.payload) {
          for (const key in options.payload) {
            resultData.payload[key] = options.payload[key];
          }
        }
      }
      return resultData;
    },

    fetchAgents () {
      this.agentsStatus = 'loading';
      this.getAgents(this.prepareGetData({ pageOptions: this.pageOptions }))
        .then((data) => {
          if (data && data.data) {
            this.agentsStatus = 'ready';
            if (data.data.length) {
              const responseData = _get(data, 'data', []);
              if (responseData.length > 0) {
                this.agentsList = [];
                this.agentsList = responseData;
              }
            }
          }
        })
        .catch(() => {
          this.$globNotify(this, 'error', 'Ошибка получения агентов!');
          this.agentsStatus = 'error';
        });
    },

    searchSubmit (query) {
      function isNotEmpty (str) {
        return !!str.replace(/\s/g, '').length;
      }
      if (isNotEmpty(query)) {
        this.agentsList = [];
        this.fetchAgents();
      } else {
        this.agentsList = [];
      }
    },
  },
};
</script>
