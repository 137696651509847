// initial state
const state = {
  showOverlay: false,
  // globalNotifications: [],
};

// getters
const getters = {
  getGlobalNotifications: state => {
    return state.globalNotifications;
  },
};

// actions
const actions = {
  toggleOverlay ({ commit, dispatch }, action) {
    if (action) {
      commit('SHOW_OVERLAY');
    } else {
      commit('CLOSE_OVERLAY');
    }
  },
};

// mutations
const mutations = {
  SHOW_OVERLAY (state) {
    state.showOverlay = true;
  },
  CLOSE_OVERLAY (state) {
    state.showOverlay = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
