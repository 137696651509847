import standardAPI from '@/api/standard_items';

const state = {
  settings_status: '',
  settings_MetaData: {},
  settings_page: 1,
  settings_perPage: 10,
};

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },

  fetch_settings ({ commit, dispatch }, itemData) {
    commit('SETTINGS_REQUEST');
    const preparedData = {
      itemData: itemData,
      method: itemData.method || 'get',
      url: itemData.url,
    };
    return new Promise((resolve, reject) => {
      standardAPI.universal_response(preparedData, (response) => {
        // console.log(response)
        if (response && response.status !== 404) {
          commit('SETTINGS_SUCCESS', response);
          commit('SETTINGS_READY');
          resolve(response);
        } else {
          commit('SETTINGS_ERROR');
          reject(response);
        }
      });
    });
  },

  edit_setting ({ commit, dispatch }, itemData) {
    commit('SETTINGS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: itemData.url,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'saved') {
          resolve(response);
        } else {
          commit('SETTINGS_READY');
          reject(response);
        }
      });
    });
  },

  settings_operations ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData: itemData,
      method: itemData.method || 'get',
      url: itemData.url,
    };
    // eslint-disable-next-line promise/param-names
    return new Promise((_resolve, _reject) => {
      standardAPI.universal_response(preparedData, (_response) => {});
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.settings_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.settings_perPage = max;
  },
  SETTINGS_READY: (state) => {
    state.settings_status = 'ready';
  },
  SETTINGS_REQUEST: (state) => {
    state.settings_status = 'loading';
  },
  SETTINGS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.settings_MetaData.meta = response.meta;
      state.settings_MetaData.links = response.links;
    }
    state.settings_status = 'success';
  },
  SETTINGS_ERROR: (state) => {
    state.settings_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
