import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const Events = () => import('@/pages/events.vue');

export default [
  {
    path: 'events',
    name: 'Events',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Мероприятия',
    },
    component: Events,
  },
  {
    path: 'events/:id',
    name: 'EventsEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Мероприятия',
    },
    component: Events,
  },
];
