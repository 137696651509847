import { roleAdmin, roleManager, roleOperator, roleSuperAdmin } from '@/mixins/roles'

const OrganisationToursTourOperators = () => import('@/pages/organisation-tours/tourOperators.vue');

export default [
  {
    path: '/organisation-tours/tourOperators',
    name: 'OrganisationToursTourOperators',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Тур операторы',
      ],
    },
    component: OrganisationToursTourOperators,
  },
  {
    path: '/organisation-tours/tourOperators/:id',
    name: 'OrganisationToursTourOperatorsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Организованные туры',
        'Тур операторы',
      ],
    },
    component: OrganisationToursTourOperators,
  },
];
