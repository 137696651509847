import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsFoodTypes = () => import('@/pages/hotels/foodTypes.vue');

export default [
  {
    path: '/hotels/food-types',
    name: 'HotelsFoodTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Типы питания',
      ],
    },
    component: HotelsFoodTypes,
  },
  {
    path: '/hotels/food-types/:id',
    name: 'HotelsFoodTypesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Типы питания',
      ],
    },
    component: HotelsFoodTypes,
  },
];
