import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const WorldToursSections = () => import('@/pages/world-tours/sections.vue');

export default [
  {
    path: '/world-tours/sections',
    name: 'WorldToursSections',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Разделы',
      ],
    },
    component: WorldToursSections,
  },
  {
    path: '/world-tours/sections/:id',
    name: 'WorldToursSectionsForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Экскурсии мир',
        'Разделы',
      ],
    },
    component: WorldToursSections,
  },
];
