import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const Partnership = () => import('@/pages/partnership.vue');

export default [
  {
    path: 'partnership',
    name: 'Partnership',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Сотрудничество',
    },
    component: Partnership,
  },
  {
    path: 'partnership/:id',
    name: 'PartnershipEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Сотрудничество',
    },
    component: Partnership,
  },
];
