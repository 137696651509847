import standardAPI from '@/api/standard_items';

const state = {
  transfers_status: '',
  transfers_MetaData: {},
  transfers_page: 1,
  transfers_perPage: 10,
};

const responseUrl = '/transfers';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_transfers ({ commit }, getOptions) {
    commit('TRANSFERS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('TRANSFERS_SUCCESS', response);
            commit('TRANSFERS_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('TRANSFERS_READY');
            reject(response);
          } else {
            commit('TRANSFERS_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_transfers_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_transfer ({ commit }, itemData) {
    commit('TRANSFERS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          commit('TRANSFERS_READY');
          resolve(response);
        } else {
          commit('TRANSFERS_READY');
          reject(response);
        }
      });
    });
  },
  edit_transfer ({ commit }, itemData) {
    commit('TRANSFERS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        try {
          if (response.status === 'updated') {
            commit('TRANSFERS_READY');
            resolve(response);
          } else {
            commit('TRANSFERS_READY');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      });
    });
  },
  remove_transfer ({ commit }, itemData) {
    commit('TRANSFERS_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          commit('TRANSFERS_READY');
          resolve();
        } else {
          commit('TRANSFERS_READY');
          reject(response);
        }
      });
    });
  },
  reorder_transfer ({ commit }, orderData) {
    const preparedData = {
      orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  set_transfer_order ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData,
      url: '/excursions/set-reorder-index/' + itemData.id,
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item_number(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  universal_response ({ commit }, itemData) {
    const preparedData = {
      itemData,
      method: itemData.method || 'put',
      url: itemData.url,
    };
    return new Promise((resolve, reject) => {
      standardAPI.universal_response(preparedData, (response) => {
        if (response.status && response.status !== 403) {
          if (response.status === 'reorder' || response.status === 'success') {
            resolve(response);
          }
        } else if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.transfers_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.transfers_perPage = max;
  },
  TRANSFERS_READY: (state) => {
    state.transfers_status = 'ready';
  },
  TRANSFERS_REQUEST: (state) => {
    state.transfers_status = 'loading';
  },
  TRANSFERS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.transfers_MetaData.meta = response.meta;
      state.transfers_MetaData.links = response.links;
    }
    state.transfers_status = 'success';
  },
  TRANSFERS_ERROR: (state) => {
    state.transfers_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
