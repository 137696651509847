import { mapState, mapActions } from 'vuex';
import uniqid from 'uniqid';

const excursionsTransfersOperations = {
  data () {
    return {
      settingsCloseModal: false,
      pricelistsIsFetched: false,
      priceListReadyCounter: 0,
      initiatePriceLists: false,
      loadingPriceLists: false,

      unionTypesList: [],
      pointTransitList: [],
      priceTypesList: [],
      levelsList: [],
      exCategoriesList: [],
      excursionTypesList: [],
      exClassesList: [],
      currenciesList: [],

      needDropOffLocation: false,
      needLimits: false,
      needReturnCity: false,

      limitItemsList: [],
      servicesItemsList: [],
      photosList: [],
    };
  },

  computed: {
    ...mapState({
      cities: (state) => state.cities.cities,
      citiesStatus: (state) => state.cities.cities_status,
      configRules: (state) => state.config_rules.config_rules,
      currenciesStatus: (state) => state.currencies.currencies_status,
      excursionCategories: (state) => state.excursion_categories.excursion_categories,
      excursionCategoriesStatus: (state) => state.excursion_categories.excursion_categories_status,
      excursionCategoriesTypes: (state) => state.excursion_categories_types.excursion_categories_types,
      excursionCategoriesTypesStatus: (state) => state.excursion_categories_types.excursion_categories_types_status,
      excursionDirections: (state) => state.excursion_directions.excursion_directions,
      excursionDirectionsStatus: (state) => state.excursion_directions.excursion_directions_status,
      excursionTypes: (state) => state.excursion_types.excursion_types,
      excursionTypesStatus: (state) => state.excursion_types.excursion_types_status,
      levels: (state) => state.levels.levels,
      levelsStatus: (state) => state.levels.levels_status,
      pcCategories: (state) => state.pc_categories.pc_categories,
      pcCategoriesStatus: (state) => state.pc_categories.pc_categories_status,
      pointsTransit: (state) => state.point_transit.point_transit,
      pointsTransitStatus: (state) => state.point_transit.point_transit_status,
      price_lists: (state) => state.price_list.price_lists,
      price_lists_status: (state) => state.price_list.price_lists_status,
      regions: (state) => state.regions.regions,
      regionsStatus: (state) => state.regions.regions_status,
    }),

    needPointCategoryInPriceItem () {
      const ruleIndex = this.chosenExClass.ex_cat_config_type;
      return this.configRules.priceList.fromExCategory[ruleIndex].pccInPriceItem;
    },

    loadedPhotosIsMainRequired () {
      return !this.formData.loadedPhotos.some((photo) => photo.is_main);
    },
  },

  watch: {
    showModal () {
      // При переключении окна - скидываем прайсы
      this.initiatePriceLists = false;
      this.pricelistsIsFetched = false;
      this.regions.forEach((region, index) => {
        this.regions[index].hasPriceList = false;
      });
    },
    'chosenExClass.value': {
      handler: function (val) {
        // eslint-disable-next-line eqeqeq
        const exClass = this.unionTypesList.find((i) => i.id == val); // need == because id is possible as int and as string
        this.chosenExClass.ex_cat_id = exClass.ex_cat_id;
        this.chosenExClass.ex_cat_type_id = exClass.ex_cat_type_id;
        this.chosenExClass.ex_cat_config_type = exClass.ex_cat_config_type;
        this.checkRule(this.chosenExClass);
        this.formData.ex_cat_id = exClass.ex_cat_id;
        this.formData.ex_cat_type_id = exClass.ex_cat_type_id;
        if (this.initiatePriceLists) {
          this.fetchPriceCategories(this.formData.ex_cat_id);
        }
      },
    },

    initiatePriceLists: {
      handler (val) {
        if (val) {
          const data = {
            ex_cat_id: this.formData.ex_cat_id,
            ex_cat_type_id: this.formData.ex_cat_type_id,
          };
          this.fetchPriceCategories(data.ex_cat_id);
        }
      },
    },
  },

  methods: {
    ...mapActions({
      getExCatTypesUnion: 'excursion_categories_types/fetch_excursion_categories_types_union',

      getCities: 'cities/fetch_cities',
      getCurrencies: 'currencies/fetch_currencies_silent',
      getExcursionCategories: 'excursion_categories/fetch_excursion_categories',
      getExcursionCategoriesTypes: 'excursion_categories_types/fetch_excursion_categories_types',
      getExcursionDirections: 'excursion_directions/fetch_excursion_directions',
      getExcursionTypes: 'excursion_types/fetch_excursion_types',
      getLevels: 'levels/fetch_levels',
      getPcCategories: 'pc_categories/fetch_pc_categories',
      getPointsTransit: 'point_transit/fetch_point_transit',
      getPriceCategories: 'price_categories/fetch_price_categories',
      getPriceList: 'price_list/fetch_price_lists',
      getRegions: 'regions/fetch_regions',
    }),

    checkRule (property) {
      const rule = this.configRules.excursion.fromExCategory;
      const index = property.ex_cat_config_type;
      if (index && rule[index]) {
        this.needDropOffLocation = rule[index].needDropOffLocation;
        this.needLimits = rule[index].needLimits;
        this.needReturnCity = rule[index].needReturnCity;
      }
    },

    fetchCities () {
      if (this.citiesStatus === 'loading') {
        return;
      }
      this.getCities(this.prepareGetData({ getAll: true }));
    },

    fetchCurrencies (type) {
      this.currenciesList = [];
      this.getCurrencies(this.prepareGetData({ getAll: true })).then((response) => {
        this.currenciesList = response.data.filter((currency) => currency.productType.indexOf(type) !== -1) || [];
      });
    },

    fetchExcursionCategories () {
      this.getExcursionCategories(this.prepareGetData({ getAll: true }));
    },

    fetchExcursionCategoriesTypes () {
      if (this.excursionCategoriesTypes.length) {
        return;
      }
      this.getExcursionCategoriesTypes(this.prepareGetData({ getAll: true }));
    },

    fetchExcursionDirections () {
      this.getExcursionDirections(this.prepareGetData({ getAll: true }));
    },

    fetchExcursionTypes () {
      this.getExcursionTypes(this.prepareGetData({ getAll: true }));
    },

    fetchLevels () {
      if (this.levels.length) {
        return;
      }
      this.getLevels(this.prepareGetData({ getAll: true }));
    },

    fetchPcCategories () {
      this.getPcCategories(this.prepareGetData({ getAll: true }));
    },

    fetchPointsTransit () {
      this.getPointsTransit(this.prepareGetData({ getAll: true }));
    },

    fetchPriceCategories (exCategoryId) {
      this.loadingPriceLists = true;
      this.getPriceCategories(this.prepareGetData({ pageOptions: { exCategoryId } }))
        .then((response) => {
          this.priceTypesList = response.data || [];
          if (this.item_id && !this.pricelistsIsFetched) {
            this.fetchPriceList(this.item_id);
          } else {
            this.loadingPriceLists = false;
          }
        })
        .catch(() => {
          this.$globNotify(this, 'error', 'Ошибка получения типов цен!');
        });
    },

    fetchPriceList (excursionId) {
      if (excursionId) {
        this.getPriceList(this.prepareGetData({ pageOptions: { excursionId } }))
          .then((response) => {
            const priceListItems = response.data || [];
            try {
              if (priceListItems.length > 0) {
                this.pricelistsIsFetched = true;
                for (let i = 0; i < priceListItems.length; i++) {
                  this.setPriceList(priceListItems[i]);
                }
              }
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {
            this.$globNotify(this, 'error', 'Ошибка получения Прейскуранта!');
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loadingPriceLists = false;
            });
          });
      }
    },

    fetchRegions () {
      this.getRegions(this.prepareGetData({ getAll: true }));
    },

    addOrderLimits () {
      this.formData.exOrderLimits.push({
        id: uniqid(),
        new: true,
        dates: '',
        order_number: '',
      });
    },

    removeOrderLimits (index, _item) {
      this.formData.exOrderLimits.splice(index, 1);
    },

    setupServicesPrices () {
      this.formData.services.forEach((service) => {
        const prices = [];
        this.currenciesList.forEach((currency) => {
          const founded = service.prices.find((i) => i.currency_id === currency.id);
          prices.push({
            id: founded ? founded.id : null,
            currency_id: currency.id,
            price: founded ? founded.price : '',
          });
        });
        service.prices = prices;
        service.loadedPhotos = [];
        if (service.photos) {
          service.loadedPhotos = service.photos.map((photo) => {
            photo.url = photo.file_name;
            return photo;
          });
        }
      });
    },

    addLoadedPhoto () {
      this.formData.loadedPhotos.push({
        id: uniqid(),
        url: '',
        title_ru: '',
        is_main: this.formData.loadedPhotos.length === 0,
      });
    },

    removeLoadedPhoto (index, _item) {
      this.formData.loadedPhotos.splice(index, 1);
    },

    toggleMainImg (item) {
      this.formData.loadedPhotos
        .filter((photo) => photo.id !== item.id)
        .forEach((photo) => {
          photo.is_main = false;
        });
    },

    setPriceList (priceListItem) {
      if (this.regions.length) {
        for (let i = 0; i < this.regions.length; i++) {
          if (this.regions[i].id === priceListItem.region_id) {
            this.$refs.priceList[i].setupPriceList(priceListItem);
            this.regions[i].hasPriceList = true;
            break;
          }
        }
      }
    },

    togglePriceLists () {
      this.initiatePriceLists = true;
      this.fetchLevels();
    },

    validatePriceLists () {
      // save all price lists
      this.$emit('openSaveOverlay', true);
      this.priceListReadyCounter = 0; // counter

      if (this.regions.length) {
        for (let i = 0; i < this.regions.length; i++) {
          this.$refs.priceList[i].validatePriceList();
        }
      }
    },

    priceListItemReady () {
      // single price list was saved
      this.priceListReadyCounter++; // increment counter
      if (this.priceListReadyCounter === this.regions.length) {
        // if price lists saved - close overlay & close modal
        this.$emit('openSaveOverlay', false);
        this.fetchData();
        if (this.settingsCloseModal) {
          this.closeModal();
        }
      }
    },

    regionHasPriceList (region, hasPriceList) {
      for (let i = 0; i < this.regions.length; i++) {
        if (this.regions[i].id === region.id) {
          this.regions[i].hasPriceList = hasPriceList;
          break;
        }
      }
    },

    beforeSave (settings) {
      this.settingsCloseModal = settings.closeModal;
      settings.closeModal = false;
      return settings;
    },

    afterSave (item) {
      if (item.photos && item.photos.length) {
        this.formData.loadedPhotos = item.photos.map((photo) => {
          photo.url = photo.file_name;
          return photo;
        });
      }
      if (item.services && item.services.length) {
        this.formData.services = item.services || [];
        this.setupServicesPrices();
      }
      if (this.initiatePriceLists) {
        // if price lists was opened - save all of them
        this.validatePriceLists();
      } else {
        // if price lists doesn't open - just close overlay & close modal
        this.$emit('openSaveOverlay', false);
        if (this.settingsCloseModal) {
          this.closeModal();
        }
      }
    },
  },
};

export default excursionsTransfersOperations;
