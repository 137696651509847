import standardAPI from '@/api/standard_items';

const state = {
  sections: [],
  sections_status: '',
  sections_MetaData: {},
  sections_page: 1,
  sections_perPage: 10,
};

const responseUrl = '/static/sections';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_sections ({ commit }, getOptions) {
    commit('SECTIONS_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        if (response && response.data) {
          commit('SECTIONS_SUCCESS', response);
          commit('SECTIONS_READY');
          resolve(response);
        } else {
          commit('SECTIONS_ERROR');
          reject(response);
        }
      }, preparedOptions);
    });
  },
  add_section ({ commit, dispatch }, itemData) {
    commit('SECTIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('SECTIONS_READY');
          reject(response);
        }
      });
    });
  },
  edit_section ({ commit, dispatch }, itemData) {
    commit('SECTIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('SECTIONS_READY');
          reject(response);
        }
      });
    });
  },
  remove_section ({ commit, dispatch }, itemData) {
    commit('SECTIONS_REQUEST');
    const preparedData = {
      itemData: itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('SECTIONS_READY');
          reject(response);
        }
      });
    });
  },
  reorder_section ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData: orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.sections_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.sections_perPage = max;
  },
  SECTIONS_READY: (state) => {
    state.sections_status = 'ready';
  },
  SECTIONS_REQUEST: (state) => {
    state.sections_status = 'loading';
  },
  SECTIONS_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.sections_MetaData.meta = response.meta;
      state.sections_MetaData.links = response.links;
    }
    state.sections = response.data;
    state.sections_status = 'success';
  },
  SECTIONS_ERROR: (state) => {
    state.sections_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
