import { roleAdmin, roleSuperAdmin, roleEditor } from '@/mixins/roles';

const BlogCategories = () => import('@/pages/blog/categories.vue');

export default [
  {
    path: 'blog/categories',
    name: 'BlogCategories',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Категории',
      ],
    },
    component: BlogCategories,
  },
  {
    path: 'blog/categories/:id',
    name: 'BlogCategoriesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleEditor],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'Блог',
        'Категории',
      ],
    },
    component: BlogCategories,
  },
];
