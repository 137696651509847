import { roleAdmin, roleSuperAdmin, roleManager } from '@/mixins/roles';

const PriceTypes = () => import('@/pages/priceTypes');

export default [
  {
    path: 'price-types',
    name: 'PriceTypes',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы цен',
    },
    component: PriceTypes,
  },
  {
    path: 'price-types/:id',
    name: 'PriceTypesEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Типы цен',
    },
    component: PriceTypes,
  },
];
